import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from '../Header/Header';
import { useMediaQuery } from 'react-responsive';
import { Card, Select } from 'antd';
import './sidebar.css';
import { TeamOutlined, TableOutlined, HomeOutlined, ScheduleOutlined, ReconciliationOutlined, LogoutOutlined, ExceptionOutlined, FileImageOutlined, MenuOutlined, WalletOutlined, StockOutlined } from '@ant-design/icons';
import FlatwareOutlinedIcon from '@mui/icons-material/FlatwareOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import InsertChartOutlinedSharpIcon from '@mui/icons-material/InsertChartOutlinedSharp';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Drawer, Button, Layout, Tooltip, Menu, theme } from 'antd';
import { BookOutlined, CommentOutlined, CommentRounded, DirectionsBusFilled, FitbitRounded, Groups2Rounded, MeetingRoomRounded, NotificationsActive, SearchOutlined } from '@mui/icons-material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import '../../components/Header/Header.css'
import { Profile } from '../authentication/profile';
import ShowChartIcon from '@mui/icons-material/ShowChart';

const { Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label: (
      <span>{label}</span>
      // <Tooltip title={label}  >
      // </Tooltip>
    ),
  };
}
/**
 * This function generates the dynamic menu items based on the result of the
 * keywords filter. The result can be either "Both matched", "playgroup", "class"
 * or "No match". Depending on the result, it will generate a different set of
 * menu items.
 * 
 * @param {string} result The result of the keywords filter.
 * @returns {array} An array of menu items.
 */

var classes = JSON.parse(sessionStorage.getItem("studentsClasses"));
const keywords = ["class", "playgroup"];

const result = keywords.filter(cls =>
  classes?.some(keyword => keyword.toLowerCase().includes(cls.toLowerCase()))
).length === keywords.length ? "Both matched" : keywords.filter(cls =>
  classes?.some(keyword => keyword.toLowerCase().includes(cls.toLowerCase()))
).length > 0 ? `${keywords.filter(cls =>
  classes?.some(keyword => keyword.toLowerCase().includes(cls.toLowerCase()))
).join(", ")}` : "No match";

const getDynamicItems = (result) => {
  // Determine the items based on the result
  const items = [];
  
  if (result === "Both matched") {
    items.push(
      getItem('PreSchool Observation Report', '94')
    );
    items.push(
      getItem("View Term Assessment", "126")
    );
    items.push(
      getItem('Schooling Assessment', '8')
    );
  } else if (result === "playgroup") {
    items.push(
      getItem('PreSchool Observation Report', '94')
    );
    items.push(
      getItem("View Term Assessment", "126")
    );
  } else if (result === "class") {
    items.push(
      getItem('Schooling Assessment', '8')
    );
  } else {
    items.push(
      getItem('PreSchool Observation Report', '94')
    );
    items.push(
      getItem('Schooling Assessment', '8')
    );
  }
  
  return items;
};

const items = [
  ///////////////////     Principal  and Franchisee ///////////////////
  getItem(
    "Admission Enquiry",
    "sub17",
    <ArticleIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Enquiry Entry", "43"),
      getItem("Enquiry View", "44"),
      getItem("Enquiry Follow Up", "45"),
      getItem("Enquiry Follow Up View", "46"),
      // getItem("Fee Quote", '47'),
    ]
  ),
  getItem(
    "Parents",
    "sub7",
    <FamilyRestroomOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Add Parent", "12"),
      getItem("View Parent", "13"),
      // getItem("Parents Profile", "14"),
      // getItem("Add Complaints/Suggestions", "15"),
      getItem("Principal Complaints / Suggestions", "16"),
    ]
  ),
  getItem(
    "Students",
    "sub2",
    <TeamOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("Add Student", "4"), getItem("View Student", "5")]
  ),
  getItem(
    "Class Activity Updates",
    "sub48",
    <TeamOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("Class Activity Updates", "108")]
  ),
  getItem(
    "Fee Collections",
    "sub44",
    <CurrencyRupeeIcon style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [getItem("Student Fees", "101"),]
    // getItem("Due Reports", "102"), ]
  ),
  getItem(
    "Fitness Parameter",
    "sub45",
    <FitbitRounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [
      getItem("Fitness Parameter", "103"),
      getItem("Fitness Analysis", "105"),
    ]
  ),
  getItem(
    "PTM",
    "sub49",
    <Groups2Rounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [
      getItem("PTM Dates", "113"),
      getItem("Parent Teacher Meeting", "109"),
      getItem("View Parent Teacher Meeting", "110"),
    ]
  ),
  getItem(
    "Message Center",
    "sub51",
    <CommentRounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [
      // getItem("Message Center", "116"),
      getItem("View Messages", "115"),
    ]
  ),
  getItem(
    "Employee Enquiry",
    "sub14",
    <ArticleIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Employee Enquiry", "27"),
      getItem("View Employee Enquiry", "57"),
      getItem("Employee Enquiry Followup", "58"),
      getItem("View Employee Enquiry Followup", "59"),

    ]
  ),

  getItem(
    "Employee",
    "sub1",
    <TeamOutlined
      className="icons"
      style={{ fontSize: "16px", color: "white" }}
    />,
    [getItem("Add Employee", "2"), getItem("Employees List", "3")]
  ),

  getItem(
    "Roles Assign",
    "sub3",
    <AccountCircleRoundedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("Roles Assign", "68")]
  ),

  getItem(
    "Time Table",
    "sub4",
    <TableOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("View TimeTable", "87")]
  ),

  getItem(
    "Teacher Assign",
    "sub32",
    <AccountCircleRoundedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("Class Assigning", "83")]
  ),



  getItem(
    "Transportation",
    "sub15",
    <DirectionsBusFilled
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Allocation Bus Routes", "31"),
      getItem("View Allocation BusRoute", "32"),
      getItem("Fee Collection", "33"),
      getItem("View Fee Collection", "34"),
    ]
  ),

  getItem(
    "Attendance",
    "sub6",
    <ScheduleOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Class Attendance", "9"),
      getItem("View Class Attendance", "10"),
      getItem("Student Attendance", "11"),
    ]
  ),

  getItem(
    "Food Updates",
    "sub11",
    <FlatwareOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Food Items", "79"),
      getItem("Food Updates", "53"),
      getItem("View Food Updates", "88"),
    ]
  ),

  // getItem(
  //   "Assessment",
  //   "sub5",
  //   <ReconciliationOutlined className="icons" style={{ fontSize: "16px" }} />,
  //   [
  //     getItem("PreSchool Monthly Assessment", "74"),
  //     getItem("ObservationReports", "107"),
  //     getItem("School Assessment Reports", "75"),
  //     getItem("View School Assessment", "8"),

  //   ]
  // ),

  getItem(
    "Assessment",
    "sub5",
    <ReconciliationOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem(
        "PreSchool",
        "subPreSchool",
        null,
        [
          getItem("Monthly Assessment", "74"),
          getItem("Observation Reports", "107"),
          getItem("Term Assessment", "125"),
          getItem("View Term Assessment", "126"),
        ]
      ),
      getItem(
        "Schooling",
        "subSchooling",
        null,
        [
          getItem("Assessment Reports", "75"),
          getItem("View Assessment", "8"),
        ]
      )
    ]
  ),


  getItem(
    "Co-Curriculum Activities",
    "sub13",
    <EmojiEventsOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Pictures Uploading", "51"),
      // getItem("View Pictures Uploads", "89"),
      getItem("Competition Awards", "26"),
      getItem("View Competition Awards", "61"),
    ]
  ),

  getItem(
    "EventAndHolidays",
    "sub46",
    <CalendarMonthIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("View Events And Holidays", "104"),
      getItem("Events Status", "124"),
    ]
  ),

  getItem(
    "Revenue",
    "sub56",
    <StockOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Revenue", "117"),
      getItem("Revenue View", "118"),
    ]
  ),

  getItem(
    "Expenses",
    "sub57",
    <WalletOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Petty Expenses", '119'),
      getItem("Petty Expenses View", '120'),
      getItem("Expenditure", '76'),
      getItem("Expenditure View", '123'),
    ]
  ),



  ////////////////////////////////////////////

  ///////////////////////               Franchisor     ///////////////////////////////////

  getItem(
    "Administration",
    "sub8",
    <MenuBookOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Add Program", "60"),
      //  getItem("View Program", '67'),
      getItem("Add Class", "17"),
      // getItem("View Class", '18'),
      getItem("Add Section", "69"),
      // getItem("View Section", '70'),
      getItem("Add Subject", "21"),
      //  getItem("View Subject", '22',),
      getItem("Add State", "54"),
      getItem("Add City", "55"),
      //  getItem("View City", '56',),
      getItem("Add Area", "71"),
      //  getItem("View Area", '72',),
      getItem("Add Frequency", "82"),
      getItem("Timings", "65"),
      getItem("Add Qualification", "91"),
      getItem("Add Competition", "92"),
      getItem("Expenses Type", '121'),
      getItem("Revenue Type", "122"),
      getItem("Fee", "sub9", null, [
        getItem("Fee Head", "62"),
        getItem("Fee Due", "64"),
        getItem("Fee Structure", "63"),
        getItem("Add Royalty Amount", "85"),
        // getItem("View Royalty Amount", '86'),
      ]),
      getItem("Transportation", "sub10", null, [
        getItem("Fee Master", "66"),
        getItem("Add Vehicle", "78"),
        getItem("View Vehicles", "81"),
        getItem("Create Lane", "77"),
        getItem("View Lanes", "84"),
      ]),

      getItem("Assessment", "sub34", null, [
        getItem("Assessment Skills", "7"),
        getItem("Assessment Parameters", "73"),
      ]),
    ]
  ),


  getItem("Franchise", "sub16", <DirectionsBusFilled style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px' }} />,
    [getItem("Franchise Enquiry", '48'),
    // getItem("Franchise Request And Suggestions", '49'), 
    getItem("Franchise View", '99'),
    getItem("Franchise Follow Up", '50'),
    getItem("Franchise Follow Up View", '98'),
    getItem("Royalty Collections", '36'),
    // getItem("Franchise Owner Creation", '37'),
    getItem("School Creation", '38'),
    getItem("View Schools", '100'),
    getItem("Franchise Request And Suggestions", "49")
    ]),


  // getItem('Roles Assign', "sub22", <AccountCircleRoundedIcon style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px' }} />,
  //   [getItem("Roles Assign", "68")]),

  getItem(
    "EventAndHolidays",
    "sub18",
    <CalendarMonthIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Event And Holidays", "24"),
      getItem("View Events And Holidays", "25"),
    ]
  ),

  getItem(
    "TimeTable",
    "sub47",
    <TableOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("Class Time Table", "6"),
    getItem("Time Table", "87")

    ]
  ),
  getItem(
    "Revenue",
    "sub52",
    <StockOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Revenue View", "118"),
    ]
  ),
  getItem(
    "Expenses",
    "sub58",
    <WalletOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Petty Expenses View", '120'),
      getItem("Expenditure View", '123'),

    ]
  ),


  ////////////////////////////////////////

  //////////////////////// Parents ////////////////////////////

  getItem(
    "Profile",
    "14",
    <FamilyRestroomOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    // [getItem("Profile", "14")]
  ),

  getItem(
    "Gallery",
    "90",
    <FileImageOutlined
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />
  ),

  getItem(
    "Fitness Parameter",
    "105",
    <FitbitRounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    // [
    // getItem("Fitness Analysis", "105"),
    // ]
  ),

  getItem(
    "PTM",
    "sub53",
    <Groups2Rounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [
      getItem("PTM Dates", "114"),
      getItem("View Parent Teacher Meeting", "110"),
    ]
  ),

  // getItem(
  //   "Observation Report",
  //   "94",
  //   <SummarizeOutlinedIcon
  //     style={{ fontSize: "18px", marginLeft: "13px", marginRight: "12px" }}
  //   />,
  //   [getItem("Observation Report", "94")]
  // ),
  // getItem(
  //   "Message Center",
  //   "sub52",
  //   <CommentRounded style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
  //   [
  //     // getItem("Message Center", "116"),
  //     getItem("View Messages", "115"),
  //   ]
  // ),
  getItem(
    "Food Updates",
    "sub20",
    <FlatwareOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("View Food Updates", "88")]
  ),

  getItem(
    "EventAndHolidays",
    "sub21",
    <CalendarMonthIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("View Events And Holidays", "104"),
    ]
  ),

  getItem(
    "Assessment",
    "sub37",
    <ReconciliationOutlined className="icons" style={{ fontSize: "16px" }} />,
    getDynamicItems(result)
    // [
    //   getItem('PreSchool Observation Report', '94'),
    //   // getItem("Schooling Assessment", "8")
    // ]
  ),

  getItem(
    "Attendance",
    "sub38",
    <ScheduleOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("Student Attendance", "11")]
  ),

  getItem(
    "Time Table",
    "sub39",
    <TableOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("View TimeTable", "106")]
  ),
  getItem(
    "Fees",
    "sub54",
    <CurrencyRupeeIcon style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }} />,
    [getItem("Student Fees", "101"),]
    // getItem("Due Reports", "102"), ]
  ),
  getItem(
    "Achievements",
    "sub55",
    <EmojiEventsOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("View Achievements", "61")]
  ),
  getItem(
    "Daily Observation Report",
    "80",
    <SummarizeOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "13px", marginRight: "12px" }}
    />
  ),

  getItem(
    "Complaint Suggestion",
    "sub40",
    <ExceptionOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Add Complaints/Suggestions", "15"),
      getItem("Principal Reply", "93"),
    ]
  ),

  getItem(
    "Requests Suggestions",
    "sub41",
    <ExceptionOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Add Requests/Suggestions", "96"),
      getItem("Franchisor Reply", "97"),
    ]
  ),

  getItem(
    "View Class Activity",
    "112",
    <SummarizeOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "13px", marginRight: "12px" }}
    />
  ),

  //////////////////////////////////////////////////////////

  /////////////    teacher ///////////////////////////////

  getItem(
    "Students",
    "sub23",
    <TeamOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("View Student", "5")]
  ),
  getItem(
    "Parents",
    "sub24",
    <FamilyRestroomOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("Parents Details", "13")]
  ),
  getItem(
    "Transportation",
    "sub25",
    <DirectionsBusFilled
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("View Allocation BusRoute", "32")]
  ),

  getItem(
    "Time Table",
    "sub35",
    <TableOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("View TimeTable", "87")]
  ),

  getItem(
    "Attendance",
    "sub26",
    <ScheduleOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Class Attendance", "9"),
      getItem("View Class Attendance", "10"),
      getItem("Student Attendance", "11"),
    ]
  ),

  getItem(
    "Food Updates",
    "sub27",
    <FlatwareOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      // getItem("Food Items", '79'),
      getItem("Food Updates", "53"),
      getItem("View Food Updates", "88"),
    ]
  ),

  getItem(
    "Assessment",
    "sub28",
    <ReconciliationOutlined className="icons" style={{ fontSize: "16px" }} />,
    [
      getItem("Pre MonthlyAssessment", "74"),
      getItem("View Assessment", "8"),
      getItem("Assessment Reports", "75"),
      getItem("Term Assessment", "125"),
      getItem("View Term Assessment", "126"),
    ]
  ),

  getItem(
    "Co-Curriculum Activities",
    "sub29",
    <EmojiEventsOutlinedIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [
      getItem("Pictures Uploading", "51"),
      getItem("View Pictures Uploads", "89"),
      getItem("Competition Awards", "26"),
      getItem("View Competition Awards", "61"),
    ]
  ),

  getItem(
    "EventAndHolidays",
    "sub30",
    <CalendarMonthIcon
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem("View Events And Holidays", "104")]
  ),

  getItem(
    "Reports",
    "sub31",
    <ReconciliationOutlined
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem(" Student Report", "80")]
  ),

  getItem(
    "Update password",
    "sub50",
    <ReconciliationOutlined
      style={{ fontSize: "18px", marginLeft: "12px", marginRight: "12px" }}
    />,
    [getItem(" Update Password", "111")]
  ),

  getItem(
    "Class Activity Updates",
    "sub36",
    <TeamOutlined className="icons" style={{ fontSize: "16px" }} />,
    [getItem("Class Activity Updates", "108")]
  ),

  // getItem("Statistics", '39', <InsertChartOutlinedSharpIcon style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px', }} />),
  // getItem("Settings", '40', < SettingsIcon style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px' }} />),
  // getItem("Staff Room", '41', <GroupsOutlinedIcon style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px' }} />),
  // getItem("Sign Out", '42', <LogoutOutlined style={{ fontSize: '18px', marginLeft: '12px', marginRight: '12px' }} />),

  //
];

const rootSubmenuKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
  "sub8",
  "sub11",
  "sub12",
  "sub13",
  "sub14",
  "sub15",
  "sub16",
  "sub17",
  "sub18",
  "sub19",
  "sub20",
  "sub21",
  "sub22",
  "sub23",
  "sub24",
  "sub25",
  "sub26",
  "sub27",
  "sub28",
  "sub29",
  "sub30",
  "sub31",
  "sub32",
  "sub36",
  "sub37",
  "sub38",
  "sub39",
  "sub40",
  "sub41",
  "sub42",
  "sub43",
  "sub44",
  "sub45",
  "sub46",
  "sub47",
  "sub48",
  "sub49",
  "sub50",
  "sub51",
  "sub52",
  "sub53",
  "sub54",
  "sub55",
  "sub56",
  "sub57",
  "sub58",

];

const Sidebar = () => {
  // const [isSmallScreen, setIsSmallScreen] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 481 });
  // const [collapsed, setCollapsed] = useState(isSmallScreen);
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([""]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (isSmallScreen) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isSmallScreen]);

  // useEffect(() => {
  //   const checkScreenSize = () => {
  //     setIsSmallScreen(window.innerWidth <= 480);
  //   };

  //   // Initial check
  //   checkScreenSize();

  //   // Event listener for screen size changes
  //   window.addEventListener('resize', checkScreenSize);

  //   // Cleanup
  //   return () => window.removeEventListener('resize', checkScreenSize);
  // }, []);

  // // Preload the image
  // useEffect(() => {
  //   if (isSmallScreen) {
  //     const img = new Image();
  //     img.src = '../../images/kidhood_logo_3.png';
  //   }
  // }, [isSmallScreen]);

  const role = sessionStorage.getItem("roleId");

  const filteredItems = items.filter((item) => {
    // principal && franchisee
    if (role == 3 || role == 2) {
      return (
        item.key === "sub1" ||
        item.key === "sub2" ||
        item.key === "sub3" ||
        item.key === "sub4" ||
        item.key === "sub5" ||
        item.key === "sub6" ||
        item.key === "sub7" ||
        item.key === "sub11" ||
        item.key === "sub12" ||
        item.key === "sub13" ||
        item.key === "sub14" ||
        item.key === "sub15" ||
        item.key === "sub17" ||
        item.key === "sub31" ||
        item.key === "sub32" ||
        item.key === "sub33" ||
        item.key === "sub41" ||
        item.key === "sub42" ||
        item.key === "sub44" ||
        item.key === "sub45" ||
        item.key === "sub46" ||
        item.key === "sub48" ||
        item.key === "sub49" ||
        item.key === "sub50" ||
        item.key === "sub51" ||
        item.key === "sub56" ||
        item.key === "sub57"

      );
    }
    // Parents
    if (role == 5) {
      return (
        item.key === "14" ||
        // item.key === "sub20" ||
        item.key === "sub21" ||
        item.key === "90" ||
        item.key === "sub37" ||
        item.key === "sub38" ||
        item.key === "sub39" ||
        item.key === "sub40" ||
        // item.key === "80" ||
        item.key === "94" ||
        item.key === "105" ||
        item.key === "112" ||
        item.key === "114" ||
        item.key === "sub53" ||
        item.key === "sub54" ||
        item.key === "sub55"
        // item.key === "sub49" ||
        // item.key === "sub50"

      );
    }

    // franchisor
    if (role == 1) {
      return (
        item.key === "sub22" ||
        item.key === "sub8" ||
        item.key === "sub9" ||
        item.key === "sub10" ||
        item.key === "sub16" ||
        item.key === "sub18" ||
        item.key === "sub34" ||
        item.key === "sub43" ||
        item.key === "sub47" ||
        item.key === "sub52" ||
        item.key === "sub58"
      );
    }

    //teacher
    if (role == 6) {
      return (
        item.key === "sub23" ||
        item.key === "sub24" ||
        item.key === "sub25" ||
        item.key === "sub26" ||
        item.key === "sub27" ||
        item.key === "sub28" ||
        item.key === "sub29" ||
        item.key === "sub30" ||
        item.key === "sub31" ||
        item.key === "sub35" ||
        item.key === "sub36"
      );
    }
    return true;
  });

  const handleMenuItemClick = (key) => {
    onCloseDrawer();
    switch (key) {
      case '1': navigate('/'); break;
      case '2': navigate('/addTeacher'); break;
      case '3': navigate('/employeeView'); break;
      case '4': navigate('/addStudent'); break;
      case '5': navigate('/viewStudent'); break;
      case '6': navigate('/timeTable'); break;
      case '7': navigate('/assessment'); break;
      case '8': navigate('/viewAssessment'); break;
      case '9': navigate('/addClassAttendance'); break;
      case '10': navigate('/viewClassAttendance'); break;
      case '11': navigate('/studentAttendance'); break;
      case '12': navigate('/addParent'); break;
      case '13': navigate('/viewParent'); break;
      case '14': navigate('/parentProfile'); break;
      case '15': navigate('/AddComplaintsSuggestions'); break;
      case '16': navigate('/PrincipalComplaintsSuggestions'); break;
      case '17': navigate('/addClass'); break;
      case '18': navigate('/ViewClass'); break;
      case '19': navigate('/classOne'); break;
      case '20': navigate('/classTwo'); break;
      case '21': navigate('/addSubject'); break;
      case '22': navigate('/ViewSubject'); break;
      case '23': navigate('/AddSchool'); break;
      case '24': navigate('/EventAndHolidays'); break;
      case '25': navigate('/ViewEventsAndHolidays'); break;
      case '26': navigate('/competitionAwards'); break;
      case '27': navigate('/EmployeeEnquiry'); break;
      case '28': navigate('/addFees'); break;
      case '29': navigate('/viewfeeDetails'); break;
      case '30': navigate('/recordsOne'); break;
      case '31': navigate('/allocationBusRoutes'); break;
      case '32': navigate('/viewAllocationBusRoute'); break;
      case '33': navigate('/TransportFeeCollection'); break;
      case '34': navigate('/ViewTransportationFeeCollection'); break;
      case '35': navigate('/TestPost'); break;
      case '36': navigate('/royaltyCollections'); break;
      case '37': navigate('/FranchiseOwnerCreation'); break;
      case '38': navigate('/SchoolCreation'); break;
      case '39': navigate('/statistics'); break;
      case '40': navigate('/settings'); break;
      case '41': navigate('/staffRoom'); break;
      case '42': navigate('/signout'); break;
      case '43': navigate('/EnquiryEntry'); break;
      case '44': navigate('/EnquiryView'); break;
      case '45': navigate('/EnquiryFollowUp'); break;
      case '46': navigate('/EnquiryFollowUpList'); break;
      case '47': navigate('/FeeQuote'); break;
      case '48': navigate('/FranchiseEnquiry'); break;
      case '49': navigate('/FranchiseRequestAndSuggestions'); break;
      case '50': navigate('/FranchiseFollowUp'); break;
      case '51': navigate('/PicturesUploading'); break;
      case '52': navigate('/recordsTwo'); break;
      case '53': navigate('/FoodUpdates'); break;
      case '54': navigate('/AddState'); break;
      case '55': navigate('/AddCity'); break;
      case '56': navigate('/ViewCity'); break;
      case '57': navigate('/ViewEmployeeEnquiry'); break;
      case '58': navigate('/EmployeeEnquiryFollowup'); break;
      case '59': navigate('/ViewEmployeeEnquiryFollowup'); break;
      case '60': navigate('/AddProgram'); break;
      case '61': navigate('/competitionAwardsView'); break;
      case '62': navigate('/FeeHead'); break;
      case '63': navigate('/FeeStructure'); break;
      case '64': navigate('/FeeDue'); break;
      case '65': navigate('/timings'); break;
      case '66': navigate('/TransportationFee'); break;
      case '67': navigate('/ViewProgram'); break;
      case '68': navigate('/RolesAssign'); break;
      case '69': navigate('/AddSection'); break;
      case '70': navigate('/ViewSection'); break;
      case '71': navigate('/AddArea'); break;
      case '72': navigate('/ViewArea'); break;
      case '73': navigate('/preschoolAssessmentParameters'); break;
      case '74': navigate('/monthlyAssessment'); break;
      case '75': navigate('/assessmentReports'); break;
      case '76': navigate('/expenseEntry'); break;
      case '77': navigate('/createLane'); break;
      case '78': navigate('/addVehicle'); break;
      case '79': navigate('/FoodItems'); break;
      case '80': navigate('/studentReport'); break;
      case '81': navigate('/addVehicleView'); break;
      case '82': navigate('/AddFrequency'); break;
      case '83': navigate('/AssignTeacherToClass'); break;
      case '84': navigate('/CreateLaneView'); break;
      case '85': navigate('/addRoyaltyAmount'); break;
      case '86': navigate('/viewRoyaltyAmount'); break;
      case '87': navigate('/viewTimeTable'); break;
      case '88': navigate('/ViewFoodUpdates'); break;
      case '89': navigate('/ViewPicturesUploads'); break;
      case '90': navigate('/ViewGallery'); break;
      case '91': navigate('/AddQualification'); break;
      case '92': navigate('/addCompetition'); break;
      case '93': navigate('/PrincipalComplaintsReply'); break;
      case '94': navigate('/ObservationReport'); break;
      case '95': navigate('/ViewReportCard'); break;
      case '96': navigate('/addRequestAndSuggestions'); break;
      case '97': navigate('/franchisorRequestReply'); break;
      case '98': navigate('/franchiseFollowUpList'); break;
      case '99': navigate('/franchiseView'); break;
      case "100": navigate('/schoolView'); break;
      case "101": navigate('/ViewStudentFee'); break;
      case "102": navigate('/StudentFeeDueReports'); break;
      case "103": navigate('/fitnessParameter'); break;
      case "104": navigate('/viewSchoolEventsAndHolidays'); break;
      case "105": navigate('/fitnessAnalysis'); break;
      case "106": navigate('/ViewStudentTimeTable'); break;
      case "107": navigate('/ObservationReports'); break;
      case "108": navigate('/classActivityUpdates'); break;
      case "109": navigate('/ParentTeacherMeeting'); break;
      case "110": navigate('/ViewParentTeacherMeeting'); break;
      case "111": navigate('/UpdatePassword'); break;
      case "112": navigate('/viewClassActivity'); break;
      case "113": navigate('/ptmDates'); break;
      case "114": navigate('/ptmDatesView'); break;
      case "115": navigate('/viewMessages'); break;
      case "116": navigate('/sendMessage'); break;
      case "117": navigate('/revenueSheet'); break;
      case "118": navigate('/revenueSheetView'); break;
      case "119": navigate('/pettyExpenses'); break;
      case "120": navigate('/pettyExpensesView'); break;
      case "121": navigate('/expensesType'); break;
      case "122": navigate('/revenueType'); break;
      case "123": navigate('/expensesEntryView'); break;
      case "124": navigate('/eventStatus'); break;
      case "125": navigate('/termAssessment'); break;
      case "126": navigate('/viewTermAssessment'); break;
      default:
    }
  };

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const userName = sessionStorage.getItem("userName");

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <div className="custom-scrollbar">
          <Sider
            className="sider-nav"
            width={280}
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
            breakpoint="md"
            collapsedWidth={0}
          >
            <div className="demo-logo-vertical" />

            <img
              src="../../images/kidhood_logo_3.png"
              className="sidebar-logo"
              style={{
                position: "sticky", // Set the position to sticky
                top: 0, // Stick to the top of the container
                width: "100%", // Occupy 100% of the container width
                zIndex: 1,
                borderRadius: "5px 5px 0 0",
              }}
            />
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={filteredItems}
              className="sider-menu"
              onClick={({ key }) => handleMenuItemClick(key)}
              openKeys={openKeys}
              onOpenChange={handleOpenChange}
            >
              {!isSmallScreen && <Profile />}
            </Menu>
          </Sider>
        </div>
        <Layout className="mainContent">
          {/* <Header /> */}

          <div className="h-card">
            <div className="h-main-div">
              {/* <div>
                <Select
                  // className="searchBar"
                  showSearch
                  placeholder="Select a person"
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onSearch={onSearch}
                  // filterOption={filterOption}
                  // options={optionsData}
                  suffixIcon={<SearchOutlined style={{ color: "#1890ff" }} />}
                />
              </div> */}

              <div className="second-div">
                {/* <NotificationsActive /> */}
                {!isSmallScreen && <Profile />}
              </div>
              <div className="leftnavmenu-Icon">
                <Button
                  // type="primary"
                  onClick={showDrawer}
                  style={{
                    display: collapsed ? "block" : "none",
                    padding: "0px 8px 4px 8px",
                  }}
                >
                  <MenuOutlined />
                </Button>
                <Drawer
                  title={userName}
                  placement="left"
                  onClose={onCloseDrawer}
                  visible={drawerVisible}
                  bodyStyle={{ padding: 0 }}
                >
                  <Menu
                    defaultSelectedKeys={["1"]}
                    mode="inline"
                    items={filteredItems}
                    className="sider-menu"
                    onClick={({ key }) => handleMenuItemClick(key)}
                    openKeys={openKeys}
                    onOpenChange={handleOpenChange}
                  />
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Button
                      style={{ width: "100%" }}
                      onClick={handleLogout}
                      icon={<LogoutOutlined />}
                    >
                      Logout
                    </Button>
                  </div>
                </Drawer>
              </div>
              <div className="smallscreen-kidlogo">
                {isSmallScreen && (
                  <img
                    src="../../images/kidhood_logo_4.png"
                    alt="Kidhood Logo"
                    height={35}
                    width={100}
                  />
                )}
              </div>
            </div>
          </div>

          <Card
            style={{
              margin: "0rem 1rem",
              // padding: 24,

              // height: '90vh',
              minHeight: "calc(92vh - 50px)", // Adjust the height as needed
              maxHeight: "calc(92vh - 50px)", // Also set a maxHeight to prevent excessive scrolling
              overflowX: "hidden", // Enable scrolling
              overflowY: "auto",
              // opacity:'0.2'
              borderRadius: "24px",
              background: "rgba(255, 255, 255, 0.60)",
              backdropFilter: "blur(2px)",
            }}
          >
            <Outlet />
          </Card>
        </Layout>
      </Layout>
    </>
  );
};
export default Sidebar;