import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import logo from '../../images/kidhood logo.png';

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: 30,
    },
    header: {
        alignItems: 'center',
        marginRight: "-280px",
        marginTop: -20
    },
    logo: {
        width: 300,
        height: 'auto',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },

    halfSection: {
        width: '50%',
    },
    halfSectionActivities: {
        width: '50%',
    },
    fullSection: {
        width: '100%',
    },
    table: {
        width: "100%",
        alignSelf: 'flex-start',
    },
    tableRow: {
        flexDirection: "row",
        borderWidth: 0.2
    },
    tableActivityRows: {
        flexDirection: "row",
        borderWidth: 0.2,
        marginLeft: 2
    },
    tableCols: {
        flexDirection: "column",
        padding: 5,

    },

    tableColHeader: {
        width: "50%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },
    tableColHeaderOral: {
        width: "30%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },

    tableColHeaderGrade: {
        width: "30%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },

    skillTableColHeader: {
        width: "50%",
        backgroundColor: '#E9F0F6',
        padding: 5,
        fontWeight: "800",
    },

    tableCol: {
        width: "50%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    tableColOral: {
        width: "30%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    tableColGrade: {
        width: "30%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    skillTableCol: {
        width: "100%",
        padding: 5,
    },

    tableCell: {
        margin: 1,
        fontSize: 10,
    },
    tableCellActivities: {
        // margin: 3,
        fontSize: 10,
    },
    title: {
        color: "#4F4F4F",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: 1.2,
        marginTop: 10
    },
    combinedSection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    studentData: {
        fontSize: 18,
        color: "#444444",
    },
    studentDataValues: {
        fontSize: 12,
        color: "#444444",
    },
    studentDataLabel: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 12,
    },
    studentDataValue: {
        color: "#4F4F4F",
    },
    studentNameValue: {
        color: "#0f0f0f",
        fontSize: 20
    },
    studentDataContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        lineHeight: 1.3
    },
    studentDataLeft: {
        width: '50%',
    },
    studentDataRight: {
        textAlign: 'right',
        width: '50%',
    },
    footer: {
        marginTop: 50,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    classTeacher: {
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold',
    },
    principal: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 'bold',
    },
    watermarkImage: {
        width: '100%',
        height: '500%',
        objectFit: 'cover',
        marginLeft: -1,
        backgroundRepeat: "repeat",
    },
    watermarkContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        flexDirection: 'row',
    },
    gradientLayer: {
        flex: 1,
        width: '100%',
        objectFit: "cover"
    },
    assesmentReport: {
        color: "#4F4F4F",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: 1.2,
        alignItems: "center",
        textAlign: "center",
        marginTop: 10
    }
});

const Watermark = () => (
    <View style={styles.watermarkContainer}>
        {/* <View style={[styles.gradientLayer, { backgroundColor: '#d16ba5', opacity: 0.04 }]} /> */}
        <View style={[styles.gradientLayer, { backgroundColor: '#c777b9', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#ba83ca', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#aa8fd8', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#9a9ae1', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#8aa7ec', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#79b3f4', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#69bff8', opacity: 0.04 }]} />
        {/* <View style={[styles.gradientLayer, { backgroundColor: '#52cffe', opacity: 0.04 }]} /> */}
        {/* <View style={[styles.gradientLayer, { backgroundColor: '#41dfff', opacity: 0.04 }]} />
        <View style={[styles.gradientLayer, { backgroundColor: '#46eefa', opacity: 0.04 }]} /> */}
    </View>
);

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

// Helper functions
const formatAssessmentReport = (report, assessmentTypee, assessment_data) => {
    const getAssessmentItem = (id) => assessment_data?.find((item) => item.id === id);

    const getAssessmentFactor = (assessmentItem) => {
        const fa1Ratio = (assessmentItem?.fa1?.scoredMarks / assessmentItem?.fa1?.maxMarks) || 0;
        const fa2Ratio = (assessmentItem?.fa2?.scoredMarks / assessmentItem?.fa2?.maxMarks) || 0;
        return Math.ceil((fa1Ratio + fa2Ratio) * 5 / 2);
    };

    let totalAssessmentFactor = 0;

    return report.map((item, index) => {
        const assessmentItem = getAssessmentItem(item?.subject?.id);
        const assessmentFactor = (assessmentTypee === 'SA1' || assessmentTypee === 'SA2') && item.subject?.name !== 'Total' ? getAssessmentFactor(assessmentItem) : 0;
        const finalAssessmentFactor = assessmentFactor > 0 ? 5 : assessmentFactor;
        if (assessmentTypee === 'SA1' || assessmentTypee === 'SA2' && item.subject?.name !== 'Total') {
            totalAssessmentFactor += finalAssessmentFactor; // Accumulate the assessment factor for non-total rows
        }

        return (
            <View style={styles.tableRow} key={index}>
                <View style={styles.tableColGrade}>
                    <Text style={styles.tableCell}>{item.subject?.name || 'N/A'}</Text>
                </View>
                <View style={styles.tableColGrade}>
                    <Text style={styles.tableCell}>{item.writtenScoreMarks}/{item.writtenMaxMarks}</Text>
                </View>
                <View style={styles.tableColOral}>
                    <Text style={styles.tableCell}>{item.oralScoreMarks}/{item.oralMaxMarks}</Text>
                </View>
                {(assessmentTypee === 'SA1' || assessmentTypee === 'SA2') && (
                    <>
                        <View style={styles.tableColOral}>
                            <Text style={styles.tableCell}>{item.projectScoreMarks}/{item.projectMaxMarks}</Text>
                        </View>
                        <View style={styles.tableColOral}>
                            <Text style={styles.tableCell}>{item.subject?.name === 'Total' ? totalAssessmentFactor : finalAssessmentFactor}</Text>
                        </View>
                    </>
                )}
                <View style={styles.tableColGrade}>
                    <Text style={styles.tableCell}>{item.totalScoreMarks}/{item.totalMaxMarks}</Text>
                </View>
                <View style={styles.tableColGrade}>
                    <Text style={styles.tableCell}>{item.grade}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{item.remarks}</Text>
                </View>
            </View>
        );
    });
};

const formatSkillDevelopmentReport = (report) => (
    report.map((item, index) => (
        <View style={styles.tableRow} key={index}>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{item.assessmentSkill?.name || 'N/A'}</Text>
            </View>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{item.grade}</Text>
            </View>
        </View>
    ))
);

const formatActivitiesProjectReport = (report) => (
    <View>
        <View style={styles.tableActivityRows}>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{"Given"}</Text>
            </View>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{report.activitiesGiven}</Text>
            </View>
        </View>
        <View style={styles.tableActivityRows}>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{"Submitted"}</Text>
            </View>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{report.activitiesSubmitted}</Text>
            </View>
        </View>
        <View style={styles.tableActivityRows}>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{"Remarks"}</Text>
            </View>
            <View style={styles.skillTableCol}>
                <Text style={styles.tableCell}>{report.remarks}</Text>
            </View>
        </View>
    </View>

    // <View style={styles.tableCols}>
    //     <View style={styles.tableCol}>
    //         <Text style={styles.tableCellActivities}>{report.activitiesGiven}</Text>
    //     </View>
    //     <View style={styles.tableCol}>
    //         <Text style={styles.tableCellActivities}>{report.activitiesSubmitted}</Text>
    //     </View>
    //     <View style={styles.tableCol}>
    //         <Text style={styles.tableCellActivities}>{report.remarks}</Text>
    //     </View>
    // </View>
);

const formatAttendanceReport = (report) => (
    report.map((item, index) => (
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.subject || 'N/A'}</Text>
            </View>


            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.april}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.may}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.june}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.july}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.august}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.september}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.october}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.november}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.december}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.january}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.february}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.march}</Text>
            </View>
        </View>
    ))
);
const getAcademicYear = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = currentYear + 1;
    return `${startYear} - ${endYear}`;
};

const AssessmentPdf = ({
    assessmentReport,
    skillDevelopmentReport,
    activitiesProjectReport,
    attendanceReport,
    student,
    assessmentType,
    assessment_data,
}) => {
    const parseReport = (report) => Array.isArray(report) ? report : (typeof report === 'string' ? JSON.parse(report) : []);

    const assessmentReports = parseReport(assessmentReport);
    const skillDevelopmentReports = parseReport(skillDevelopmentReport);
    const activitiesReport = typeof activitiesProjectReport === 'string' ? JSON.parse(activitiesProjectReport) : {};
    const attendanceReports = parseReport(attendanceReport);
    const studentData = parseReport(student);
    const assessmentTypee = parseReport(assessmentType);
    const studentClassName = studentData.studentEnrollments?.[0]?.classesClassSection?.class?.name || 'N/A';
    const studentClassSectionName = studentData.studentEnrollments?.[0]?.classesClassSection?.classSection?.name || null;
    const enrollmentDate = studentData.studentEnrollments?.[0]?.date || 'N/A';

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.header}>
                    <Image style={styles.logo} src={logo} />
                </View>

                <View style={styles.section}>
                    <View style={styles.studentDataContainer}>
                        <View style={styles.studentDataLeft}>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Student Name:</Text> <Text style={styles.studentDataValue}>{studentData.name || 'N/A'}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>DOB:</Text> <Text style={styles.studentDataValue}>{formatDate(studentData.dateOfBirth) || 'N/A'}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Assessment Type:</Text> <Text style={styles.studentDataValue}>{assessmentTypee || 'N/A'}</Text></Text>
                        </View>
                        <View style={styles.studentDataRight}>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Class:</Text> <Text style={styles.studentDataValue}>{studentClassSectionName ? studentClassSectionName : studentClassName}</Text></Text>
                            {/* {studentClassSectionName ? (<Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Section:</Text> <Text style={styles.studentDataValue}>{studentClassSectionName}</Text></Text>) : null} */}
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Date Of Joining:</Text> <Text style={styles.studentDataValue}>{formatDate(studentData.studentEnrollments?.[0]?.date)}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Academic Year:</Text> <Text style={styles.studentDataValue}>{getAcademicYear()}</Text></Text>
                        </View>
                    </View>
                </View>

                {/* <Watermark /> */}

                <View style={styles.fullSection}>
                    <Text style={styles.assesmentReport}>Assessment Report</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Subject</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Written</Text></View>
                            <View style={styles.tableColHeaderOral}><Text style={styles.tableCell}>Oral</Text></View>
                            {['SA1', 'SA2'].includes(assessmentTypee) && (
                                <>
                                    <View style={styles.tableColHeaderGrade}>
                                        <Text style={styles.tableCell}>Project</Text>
                                    </View>
                                    <View style={styles.tableColHeaderOral}>
                                        <Text style={styles.tableCell}>FA1+FA2</Text>
                                    </View>
                                </>
                            )}
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Total</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Grade</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Remarks</Text></View>
                        </View>
                        {assessmentReports.length > 0 ? formatAssessmentReport(assessmentReports, assessmentTypee, assessment_data) : <Text>No data available</Text>}
                    </View>
                </View>

                {/* <Watermark /> */}

                <View style={styles.combinedSection}>
                    <View style={styles.halfSection}>
                        <Text style={styles.assesmentReport}>Skill Development Report</Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.skillTableColHeader}>
                                    <Text style={styles.tableCell}>Skill</Text>
                                </View>
                                <View style={styles.skillTableColHeader}>
                                    <Text style={styles.tableCell}>Grade</Text>
                                </View>
                            </View>
                            {formatSkillDevelopmentReport(skillDevelopmentReports)}
                        </View>
                    </View>
                    <View style={styles.halfSection}>
                        <Text style={styles.assesmentReport}>Activities & Project Report</Text>
                        <View style={styles.table}>
                            <View style={styles.tableActivityRows}>
                                <View style={styles.skillTableColHeader}>
                                    <Text style={styles.tableCell}>Subject</Text>
                                </View>
                                <View style={styles.skillTableColHeader}>
                                    <Text style={styles.tableCell}>Grade</Text>
                                </View>
                            </View>
                            {formatActivitiesProjectReport(activitiesReport)}
                        </View>
                    </View>
                </View>
                {/* <Watermark /> */}

                <View style={styles.section}>
                    <Text style={styles.title}>Attendance Report</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Subject</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Apr</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>May</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jun</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jul</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Aug</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Sep</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Oct</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Nov</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Dec</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jan</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Feb</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Mar</Text></View>

                        </View>
                        {attendanceReports.length > 0 ? formatAttendanceReport(attendanceReports) : <Text>No data available</Text>}
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text style={styles.classTeacher}>Class Teacher</Text>
                    <Text style={styles.principal}>Principal</Text>
                </View>
            </Page>
        </Document>
    );
};

AssessmentPdf.propTypes = {
    assessmentReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    skillDevelopmentReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    activitiesProjectReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    attendanceReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    student: PropTypes.object,
};

export default AssessmentPdf;
