import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../../Api/api';
import { token } from '../../../Api/token';
import { responseFormat } from '../../Auth/errorResponse';

const slice = createSlice(
    {
        name: 'UpdatePreSchoolAssessment',
        initialState: {
            data: null,
            isLoading: false,
            hasError: false,
        },
        reducers: {
            fetchPreSchoolTermAssessmentReportData: (state) => {
                state.isLoading = true;
                state.hasError = false;
            },
            fetchPreSchoolTermAssessmentReportSuccess: (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            },
            fetchPreSchoolTermAssessmentReportFailure: (state) => {
                state.isLoading = false;
                state.hasError = true;
            },
            setSuccessMessage: (state, action) => {
                state.successMessage = action.payload;
            },
            setShowSuccessMessage: (state, action) => {
                state.showSuccessMessage = action.payload;
            },
            setErrorMessage: (state, action) => {
                state.errorMessage = action.payload;
            },
            setShowErrorMessage: (state, action) => {
                state.showErrorMessage = action.payload;
            },
        },
    }


);

export const { fetchPreSchoolTermAssessmentReportData, fetchPreSchoolTermAssessmentReportSuccess, fetchPreSchoolTermAssessmentReportFailure, setSuccessMessage, setShowSuccessMessage, setErrorMessage, setShowErrorMessage } = slice.actions;


export const UpdatePreSchoolTermAssessment = (franchiseeSchoolId, studentId, putData) => async (dispatch) => {
    dispatch(fetchPreSchoolTermAssessmentReportData());
    const tokenId = token();
    // const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");

    try {
        // Make your POST request here using the fetch function or an HTTP library like Axios
        const response = await fetch(API_PATH + `/franchisee-school/${franchiseeSchoolId}/pre-school-term-assessments/${studentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${tokenId}`
            },
            body: JSON.stringify(putData),
        });

        if (!response.ok) {
            // throw new Error('Request failed't); // You can customize error handling
            const responseData = await response.json();
            throw new Error(JSON.stringify(responseData));
        }

        const responseData = await response.json();

        dispatch(fetchPreSchoolTermAssessmentReportSuccess(responseData));
        dispatch(setSuccessMessage("Successful dfsdfs!"));
        dispatch(setShowSuccessMessage(true));


    } catch (error) {
        dispatch(fetchPreSchoolTermAssessmentReportFailure());
        const errMessage = responseFormat({ error })
        dispatch(setErrorMessage(errMessage));
        dispatch(setShowErrorMessage(true));
    }
};


export default slice.reducer;
