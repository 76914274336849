import React, { useState, useMemo, useEffect } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import SearchableDropDownField from '../../ReusableFields/DropDown/SearchableDropDownField';
import SuccessMessagePopup from '../reUsableForm/pop-card/successfulMessage';
import TextInputField from "../reUsableForm/textInputField";
import { useDispatch, useSelector } from 'react-redux';
import { validationRegexAlphaNumeric } from '../reUsableForm/regex';
import { postCompetetionAwards } from "./slicerCompetetion/requestsCompetetion";
import PastDateField from '../../ReusableFields/DateFields/PastDateField';
import { getCompetetionNames } from './slicerCompetetion/requestsCompetetion';
import { CLSTIPostCompetetion } from './slicerCompetetion/competitionAwardsSlicer';
import { ErrorMessagePopup } from '../reUsableForm/pop-card/errorMessage';
import { fetchStudentDataOnce } from '../StudentDetails/viewStudentSlicer';
import SelectDropdown from "../SelectDropdown";
import { fetchFranchiseClasswiseSectionsDataOnce } from "../Assessment/slicer/viewfranchiseclasswisesectionsSlicer";
import { uploadFiles } from '../reUsableForm/uploadImage-docs';
import { token } from '../../Api/token';
import { API_PATH } from '../../Api/api';

const CompetitionAwards = () => {
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [dropDowns, setDropDowns] = useState({});
  const [selectedClassData, setSelectedClassData] = useState({ classSections: [] });
  const [documents, setDocuments] = useState([]);
  const [submit, setSubmit] = useState(false);

  const fileNamesArray = Array.from(
    { length: documents.length },
    (_, index) => documents[index].name
  );

  const defaultData = {
    date: '',
    studentId: '',
    name: '',
    class: '',
    section: '',
    competitionId: '',
    award: '',
    imageUrls: [],
    result: ""
  }

  const [formErrors, setFormErrors] = useState({
    class: false,
    monthName: false,
    day: false,
    classesSectionsSubject: false,
    student: false
  });

  const [formData, setFormData] = useState({
    date: '',
    studentId: '', // studentId will be stored as ID
    name: '',
    class: '',
    section: '',
    competitionId: '',
    award: '',
    imageUrls: [],
    result: ''
  });

  useEffect(() => {
    if ((franchiseeSchoolId)) {
      dispatch(fetchFranchiseClasswiseSectionsDataOnce());
    }
  }, []);

  const classesClassSectionData = useSelector((state) => state.viewFranchiseClasswiseSections.data) || [];

  const backendIds = { studentId: null, competitionId: null };
  const getCompetetionNamesObj = useSelector(state => state.competetionAwardsReducer?.getCompetetionNames);
  const postCompetetionAwardsObj = useSelector(state => state.competetionAwardsReducer?.postCompetetionAwards);
  const postLoading = postCompetetionAwardsObj?.loading;
  const postError = postCompetetionAwardsObj?.error;

  const competetionNames = getCompetetionNamesObj?.data;
  const dropDownNames = competetionNames?.map((each) => {
    return { id: each.id, label: each.name, value: each.name }
  })
  const franchiseeSchoolId = sessionStorage.getItem('franchiseeSchoolId')
  const studentData = useSelector((state) => state.viewStudent.data) || [];

  const activeRollNumbers = studentData?.map(each => {
    const active = each?.studentEnrollments?.find(e => e?.isActive === true);
    return active;
  })

  const rollNumbers = studentData?.map(each => {
    return {
      studentId: each?.id,
      label: each?.name, value: each?.name,
      class: each?.studentEnrollments?.[0]?.classesClassSection?.class?.name,
      section: each?.studentEnrollments?.[0]?.classesClassSection?.classSection?.name
    }
  });

  const dispatch = useDispatch();

  const loading = getCompetetionNamesObj?.loading;
  useEffect(() => {
    if (loading === 'idle') {
      dispatch(getCompetetionNames())
    }
  }, [loading])

  const allFalse = {
    validatedDate: false,
    validatedCompetetion: false,
    validatedStudent: false,
    validatedAward: false,
    validatedResult: false,
    // validatedImageUrls: false
  }

  const allTrue = useMemo(() => {
    const trueObj = { ...allFalse };
    for (const i in trueObj) {
      trueObj[i] = true
    }
    return trueObj;
  }, [JSON.stringify(allFalse)]);

  const [errors, setErrors] = useState(allFalse);
  const [validated, setValidated] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [ids, setIds] = useState(backendIds);
  const [selectStudent, setSelectStudent] = useState();
  const [documentFiles, setDocumentFiles] = useState([]);
  const [uploadTrigger, setUploadTrigger] = useState(false);
  const [docUploadError, setDocUploadError] = useState('');



  const storeResponse = () => {
    if (postLoading === 'fulfilled') {
      setShowSuccessMsg(true);
    } else if (postLoading === 'rejected') {
      setShowErrorMsg(true);
    }
  }
  useEffect(() => {
    storeResponse()
  }, [postLoading])

  const handleDocumentChange = async (event) => {
    try {
      const files = event.target.files;
      setDocuments(files);
      setFormData({ ...formData, imageUrls: files });
    } catch (error) {
      setDocUploadError('Failed to upload files');
      console.error('File upload error:', error);
    }
  };


  const displayErrorMsg = () => {
    if (showErrorMsg && docUploadError) {
      return 'cerificates are not uploaded!'
    } else if (showErrorMsg) {
      return Array.isArray(postError) ?
        postError?.map(e => { return <li>{e.message}</li> }) : postError
    }
  }

  const handleClear = () => {
    setFormData(defaultData);
    setErrors(allFalse);
    setValidated(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true)
    const form = e.currentTarget;

    if (form.checkValidity() === false ||
      !formData.competitionId || !formData.award || !formData.date ||
      !formData.imageUrls || !formData.studentId || !formData.result
    ) {

      e.stopPropagation();
      setValidated(true)
      setErrors(allTrue);
      setFormErrors(prev => ({
        ...prev,
        class: !formData.class,
        student: !formData.studentId,
        result: !formData.result,
        competitionId: !formData.competitionId,
        award: !formData.award,
        imageUrls: !formData.imageUrls.length,
      }));
      setSubmit(false)
      return;
    }

    try {
      if (documents.length > 0) {
        var uploadResponses = await uploadFiles(documents, "gallery");
      }

      const uploadLocations = uploadResponses?.map((response) => response.Location);
      const requiredData = {
        franchiseeSchoolId: +franchiseeSchoolId,
        date: formData.date,
        studentId: selectStudent.studentId,
        competitionId: +ids.competitionId,
        imageUrls: uploadLocations,
        award: formData.award,
        result: formData.result
      }

      dispatch(postCompetetionAwards(requiredData))
    } catch (error) {
      console.error(error);
      setSubmit(false);
    }

    setIds(backendIds);
    setErrors(allFalse);
    setValidated(false);
    setSubmit(false)
  }

  const handleClassChange = (classId) => {
    setSelectedSection("");
    setSelectedClassData({ classSections: [] });
    setFormErrors({ ...formErrors, class: false });

    const classSectionData = classesClassSectionData.find((c) => {
      if (Array.isArray(selectedClass)) {
        return c.classSections.some(section => selectedClass.includes(section.classesclassSection));
      } else {
        return c.id === classId;
      }
    });

    if (classSectionData) {
      setSelectedClassData(classSectionData);
    }
  };

  useEffect(() => {
    handleClassChange(selectedClass);
    setDropDowns({ ...dropDowns, sections: "" });
  }, [selectedClass]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  useEffect(() => {
    const classSection = selectedSection ? selectedSection : selectedClass ? selectedClass : null;

    if (classSection) {
      const classSectionId = Array.isArray(classSection) ? classSection.map(id => `classSectionId=${id}`).join('&') : `classSectionId=${classSection}`;
      dispatch(fetchStudentDataOnce(franchiseeSchoolId, classSectionId));
    }
  }, [dispatch, selectedSection, selectedClass]);

  const students = useSelector((state) => state.viewStudent.data) || [];
  const studentOptions = students.map(student => ({
    id: student.id,
    value: student.name,
    label: student.name,
  }));

  return (
    <>
      <div className='content-header' style={{ margin: '1rem' }}>Competition Awards</div>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <PastDateField
              name="date"
              placeholder="date"
              required
              value={formData.date}
              onChange={(e) => {
                setFormData({ ...formData, date: e.target.value });
                setErrors({ ...errors, validatedDate: false })
              }}
            />
            {
              errors.validatedDate && !formData.date ? <div className="error-message" >Date is Required!</div> : null
            }
          </Col>
        </Row>
        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <SelectDropdown
              label="Class"
              // value={formData.classId || ""}
              value={
                dropDowns.class
                  ? { value: dropDowns.class, label: dropDowns.class }
                  : null
              }
              placeholder="select class"
              onChange={(selectedOption) => {
                handleChange("classId", selectedOption);
                setSelectedClass(selectedOption.value);
                setDropDowns({ ...dropDowns, class: selectedOption.label });
                setFormErrors({ ...formErrors, class: false });
              }}
              options={classesClassSectionData.map((classItem) => ({
                id: classItem.id,
                value: classItem.classSections.length > 0
                  ? classItem.classSections.map(section => section.classesclassSection)
                  : classItem.classesclassSection,
                label: classItem.name,
              }))}
              required
              feedbackMessage="Please select a valid class"
            />
            {formErrors.class && (
              <div className="error-message">Please Select class.</div>
            )}
          </Col>

          {selectedClassData && selectedClassData.classSections && selectedClassData.classSections.length > 0 && (
            <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
              <SelectDropdown
                label="Section"
                value={
                  dropDowns.sections
                    ? {
                      value: dropDowns.sections,
                      label: dropDowns.sections,
                    }
                    : null
                }
                placeholder="Select section"
                onChange={(selectedOption) => {
                  handleChange("sectionId", selectedOption);
                  setSelectedSection(selectedOption.value);
                  setDropDowns({
                    ...dropDowns,
                    sections: selectedOption.label,
                  });
                  setFormErrors({ ...formErrors, sections: false });
                }}
                options={selectedClassData.classSections.map((section) => ({
                  key: section.id,
                  value: section.classesclassSection,
                  label: section.name,
                }))}
                required
                feedbackMessage="Please select a valid section"
              />
              {formErrors.sections && (
                <div className="error-message">Please Select Section.</div>
              )}
            </Col>
          )}


          {selectedSection && (
            <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
              <SelectDropdown
                label="Student"
                value={formData.studentId ? { value: formData.studentId, label: formData.studentId } : null}
                placeholder="Select student"
                onChange={(selectedOption) => {
                  handleChange("studentId", selectedOption.value);
                  setFormErrors({ ...formErrors, student: false });
                  setSelectStudent({ ...selectStudent, studentId: selectedOption.id });
                }}
                options={studentOptions}
                required
                feedbackMessage="Please select a valid student"
              />
              {formErrors.student && <div className="error-message">Please Select Student.</div>}
            </Col>
          )}

          <Col sm={12} xs={12} md={6} lg={3} className="mtop3">
            <Form.Group>
              <div className="uploadDoc">
                <div className='layout1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='24'
                    viewBox='0 0 25 24'
                    fill='none'
                  >
                    <path
                      d='M11.5 16V7.85L8.9 10.45L7.5 9L12.5 4L17.5 9L16.1 10.45L13.5 7.85V16H11.5ZM6.5 20C5.95 20 5.479 19.804 5.087 19.412C4.695 19.02 4.49934 18.5493 4.5 18V15H6.5V18H18.5V15H20.5V18C20.5 18.55 20.304 19.021 19.912 19.413C19.52 19.805 19.0493 20.0007 18.5 20H6.5Z'
                      fill='#636363'
                    />
                  </svg>
                  <div className='title1'>Upload&nbsp; Certificates</div>
                </div>
                <input
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif"
                  multiple
                  name='imageUrls'
                  onChange={handleDocumentChange}
                  className='uploadDocInput'
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                    position: 'absolute',
                    zIndex: 999,
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
              <div style={{ fontSize: "0.7rem" }}>Please ensure each file is less than 5MB in size.</div>
              <div>
                {fileNamesArray?.map((name, index) => {
                  return <p key={index}>{name}</p>;
                })}
              </div>
            </Form.Group>
            {/* {
              errors.validatedImageUrls && formData.imageUrls.length === 0 ? <div className="error-message">certificates are Required!</div> : null
            } */}
            {
              formData?.imageUrls?.length > 0 ? <p>{formData.imageUrls.length} &nbsp;Certificates are Selected!</p> : ''
            }
          </Col>
        </Row>
        <Row>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <SearchableDropDownField
              name='competitionId'
              value={formData.competitionId ? { label: formData.competitionId } : null}
              onChange={(e) => {
                setFormData({ ...formData, competitionId: e.value });
                setIds({ ...ids, competitionId: e.id });
                setErrors({ ...errors, validatedCompetetion: false })
              }}
              placeholder='Competetion'
              options={dropDownNames ? dropDownNames : []}
              feedbackMessage='Competetion is Required!'
              validated={validated}
              required={true}
            />
            {
              errors.validatedCompetetion && !formData.competitionId ? <div className="error-message">Enter Competetion</div> : null
            }
          </Col>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <SearchableDropDownField
              name='award'
              value={formData.award ? { label: formData.award } : null}
              onChange={(e) => {
                setFormData({ ...formData, award: e.value });
                setErrors({ ...errors, validatedAward: false })
              }}
              placeholder='Award'
              options={[{ id: 1, label: '1st Prize', value: '1st Prize' }, { id: 2, label: '2nd Prize', value: '2nd Prize' }, { id: 3, label: '3rd prize', value: '3rd prize' }, { id: 4, label: 'Consolation', value: 'Consolation' },]}
              feedbackMessage='Award is Required!'
              validated={validated}
              required={true}
            />
            {
              errors.validatedAward && !formData.award ? <div className="error-message">Award is Required!</div> : null
            }
          </Col>
          <Col className='mtop3' sm={12} xs={12} md={6} lg={3}>
            <TextInputField
              name="result"
              placeholder="Result"
              textType="Alphabet"
              validated={errors.validatedResult}
              required={true}
              maxLength={25}
              feedbackMessage="Result is Required!"
              validationErrorMessage="Result should contain either alphabets or numbers!"
              value={formData.result}
              validationRegex={validationRegexAlphaNumeric}
              onChange={(e) => {
                setFormData({ ...formData, result: e.target.value });
                setErrors({ ...errors, validatedResult: false })
              }}
            />
            {
              errors.validatedResult && !formData.result ? <div className="error-message" >Result is Required!</div> : null
            }
          </Col>


        </Row>
        {
          postLoading === 'fulfilled' && showSuccessMsg &&
          <SuccessMessagePopup show={showSuccessMsg} onClose={() => { setShowSuccessMsg(false); dispatch(CLSTIPostCompetetion()); handleClear() }} successMessage='Awards details submitted successfully!' />
        }
        {
          // postLoading === 'rejected' && showErrorMsg &&
          <ErrorMessagePopup show={showErrorMsg} onClose={() => { dispatch(CLSTIPostCompetetion()); setShowErrorMsg(false); setDocUploadError(false) }}
            errorMessage={displayErrorMsg()} />
        }
        <div className='mtop3'
          style={{
            display: "flex",
            justifyContent: "center",
            // marginBottom: "1%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit" >
            {submit ? <Spinner animation="border" /> : "Submit"}
          </button>
        </div>
      </Form>
    </>
  )
}

export default CompetitionAwards;