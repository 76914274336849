import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import logo from '../../images/kidhood logo.png';

const styles = StyleSheet.create({
    page: {
        position: 'relative',
        padding: 30,
    },
    header: {
        alignItems: 'center',
        marginRight: "-280px",
        marginTop: -20
    },
    logo: {
        width: 300,
        height: 'auto',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
    },

    halfSection: {
        width: '50%',
    },
    halfSectionActivities: {
        width: '50%',
    },
    fullSection: {
        width: '100%',
    },
    table: {
        width: "100%",
        alignSelf: 'flex-start',
    },
    tableRow: {
        flexDirection: "row",
        borderWidth: 0.2
    },
    tableActivityRows: {
        flexDirection: "row",
        borderWidth: 0.2,
        marginLeft: 2
    },
    tableCols: {
        flexDirection: "column",
        padding: 5,

    },

    tableColHeader: {
        width: "50%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },
    tableColHeaderOral: {
        width: "30%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },

    tableColHeaderGrade: {
        width: "30%",
        backgroundColor: '#E9F0F6',
        padding: 2,
        textAlign: 'center',
        fontWeight: "800",
        borderWidth: 0.2
    },

    skillTableColHeader: {
        width: "50%",
        backgroundColor: '#E9F0F6',
        padding: 5,
        fontWeight: "800",
    },

    tableCol: {
        width: "50%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    tableColOral: {
        width: "30%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    tableColGrade: {
        width: "30%",
        padding: 5,
        textAlign: 'center',
        borderWidth: 0.2
    },
    skillTableCol: {
        width: "100%",
        padding: 5,
    },

    tableCell: {
        margin: 1,
        fontSize: 10,
    },
    tableCellActivities: {
        // margin: 3,
        fontSize: 10,
    },
    title: {
        color: "#4F4F4F",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: 1.2,
        marginTop: 10
    },
    combinedSection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    studentData: {
        fontSize: 18,
        color: "#444444",
    },
    studentDataValues: {
        fontSize: 12,
        color: "#444444",
    },
    studentDataLabel: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: 12,
    },
    studentDataValue: {
        color: "#4F4F4F",
    },
    studentNameValue: {
        color: "#0f0f0f",
        fontSize: 20
    },
    studentDataContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        lineHeight: 1.3
    },
    studentDataLeft: {
        width: '50%',
    },
    studentDataRight: {
        textAlign: 'right',
        width: '50%',
    },
    footer: {
        position: 'absolute',
        bottom: 30, // Adjust this value as needed for spacing from the bottom
        left: 0,
        right: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 20, // Optional: Add padding for better alignment
    },
    classTeacher: {
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold',
    },
    principal: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 'bold',
    },
    watermarkImage: {
        width: '100%',
        height: '500%',
        objectFit: 'cover',
        marginLeft: -1,
        backgroundRepeat: "repeat",
    },
    watermarkContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        flexDirection: 'row',
    },
    gradientLayer: {
        flex: 1,
        width: '100%',
        objectFit: "cover"
    },
    assesmentReport: {
        color: "#4F4F4F",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: 1.2,
        alignItems: "center",
        textAlign: "center",
        marginTop: 10
    }
});

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

// Helper functions
const formatAssessmentReport = (report) => (
    report.map((item, index) => (
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.subject?.name || 'N/A'}</Text>
            </View>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.writtenScoreMarks}/{item.writtenMaxMarks}</Text>
            </View>
            <View style={styles.tableColOral}>
                <Text style={styles.tableCell}>{item.oralScoreMarks}/{item.oralMaxMarks}</Text>
            </View>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.totalScoreMarks}/{item.totalMaxMarks}</Text>
            </View>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.grade}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.remarks}</Text>
            </View>
        </View>
    ))
);

const formaEventStatus = (report) => (
    report.map((item, index) => (
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.eventHoliday?.name || 'N/A'}</Text>
            </View>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{item.isConductedValue}</Text>
            </View>
            <View style={styles.tableColOral}>
                <Text style={styles.tableCell}>{formatDate(item.conductedFromDate)}</Text>
            </View>
            <View style={styles.tableColGrade}>
                <Text style={styles.tableCell}>{formatDate(item.conductedToDate)}</Text>
            </View>
        </View>
    ))
);

const formatAttendanceReport = (report) => (
    report.map((item, index) => (
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.subject || 'N/A'}</Text>
            </View>


            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.april}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.may}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.june}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.july}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.august}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.september}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.october}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.november}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.december}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.january}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.february}</Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.march}</Text>
            </View>
        </View>
    ))
);
const getAcademicYear = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;
    const endYear = currentYear + 1;
    return `${startYear} - ${endYear}`;
};

const TermAssessmentPdf = ({ data }) => {
    const parseReport = (report) => Array.isArray(report) ? report : (typeof report === 'string' ? JSON.parse(report) : []);

    const assessmentReports = parseReport(data.assessmentReport);
    const attendanceReports = parseReport(data.attendanceReport);
    const studentData = parseReport(data.student);
    const assessmentType = parseReport(data.assessmentType);
    const studentClassName = studentData.studentEnrollments?.[0]?.classesClassSection?.class?.name || 'N/A';
    const studentClassSectionName = studentData.studentEnrollments?.[0]?.classesClassSection?.classSection?.name || null;
    const enrollmentDate = studentData.studentEnrollments?.[0]?.date || 'N/A';


    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.header}>
                    <Image style={styles.logo} src={logo} />
                </View>

                <View style={styles.section}>
                    <View style={styles.studentDataContainer}>
                        <View style={styles.studentDataLeft}>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Student Name:</Text> <Text style={styles.studentDataValue}>{studentData.name || 'N/A'}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>DOB:</Text> <Text style={styles.studentDataValue}>{formatDate(studentData.dateOfBirth) || 'N/A'}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Assessment Type:</Text> <Text style={styles.studentDataValue}>{assessmentType || 'N/A'}</Text></Text>
                        </View>
                        <View style={styles.studentDataRight}>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Class:</Text> <Text style={styles.studentDataValue}>{studentClassSectionName ? studentClassSectionName : studentClassName}</Text></Text>
                            {/* {studentClassSectionName ? (<Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Section:</Text> <Text style={styles.studentDataValue}>{studentClassSectionName}</Text></Text>) : null} */}
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Date Of Joining:</Text> <Text style={styles.studentDataValue}>{formatDate(studentData.studentEnrollments?.[0]?.date)}</Text></Text>
                            <Text style={styles.studentDataValues}><Text style={styles.studentDataLabel}>Academic Year:</Text> <Text style={styles.studentDataValue}>{getAcademicYear()}</Text></Text>
                        </View>
                    </View>
                </View>

                {/* <Watermark /> */}

                <View style={styles.fullSection}>
                    <Text style={styles.assesmentReport}>Assessment Report</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Subject</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Written</Text></View>
                            <View style={styles.tableColHeaderOral}><Text style={styles.tableCell}>Oral</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Total</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Grade</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Remarks</Text></View>
                        </View>
                        {assessmentReports.length > 0 ? formatAssessmentReport(assessmentReports) : <Text>No data available</Text>}
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.assesmentReport}>Grade</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Percentage</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>91% to 100%</Text></View>
                            <View style={styles.tableColHeaderOral}><Text style={styles.tableCell}>81% to 90%</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>71% to 80%</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>61% to 70%</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>51% to 60%</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>41% to 50%</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Below to 40%</Text></View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Grade</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>A1</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>A2</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>B1</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>B2</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>C1</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>C2</Text></View>
                            <View style={styles.tableColGrade}><Text style={styles.tableCell}>D</Text></View>
                        </View>
                    </View>
                </View>

                <View style={styles.fullSection}>
                    <Text style={styles.assesmentReport}>Events</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Name</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Has Conducted</Text></View>
                            <View style={styles.tableColHeaderOral}><Text style={styles.tableCell}>Started on</Text></View>
                            <View style={styles.tableColHeaderGrade}><Text style={styles.tableCell}>Ended on</Text></View>
                        </View>
                        {assessmentReports.length > 0 ? formaEventStatus(data.eventStatus) : <Text>No data available</Text>}
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.assesmentReport}>Attendance Report</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Subject</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Apr</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>May</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jun</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jul</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Aug</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Sep</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Oct</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Nov</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Dec</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Jan</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Feb</Text></View>
                            <View style={styles.tableColHeader}><Text style={styles.tableCell}>Mar</Text></View>

                        </View>
                        {attendanceReports.length > 0 ? formatAttendanceReport(attendanceReports) : <Text>No data available</Text>}
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text style={styles.classTeacher}>Class Teacher</Text>
                    <Text style={styles.principal}>Principal</Text>
                </View>
            </Page>
        </Document>
    );
};

TermAssessmentPdf.propTypes = {
    assessmentReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    skillDevelopmentReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    activitiesProjectReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    attendanceReport: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    student: PropTypes.object,
};

export default TermAssessmentPdf;
