import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import TextInputField from "../reUsableForm/textInputField";
import SelectDropdown from "../SelectDropdown";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table3";
import { TableCell } from "@mui/material";
import SkillTable from "../reUsableForm/table/table1";
import "../../components/reUsableForm/table/table.css";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchAssessmentDataOnce } from "./slicer/viewAssessmentSlicer";
import { UpdateAssessmentReportRequest, setShowSuccessMessage, setShowErrorMessage } from "./slicer/updateAssessmentReportSlicer";
import { useLocation, useNavigate } from 'react-router-dom';
import { validationRegexPinNo, validationRegexOnlyAlpha1, validationRegexAddress } from "../reUsableForm/regex";
import { token } from "../../Api/token";
import { API_PATH } from "../../Api/api";

const EditAssessmentReport = () => {
    const [validated, setValidated] = useState(false);
    const [dropDowns, setDropDowns] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const { assessmentData, assessmentType } = location.state || {};
    const assessmentActivitiesData = Object.values(assessmentData.overallReport.activitiesProjectReport)

    const [formData, setFormData] = useState({
        studentId: assessmentData.overallReport.studentDetails.id,
        assessment: assessmentType,
        studentName: assessmentData.overallReport.studentDetails.name || '',
        class: assessmentData.overallReport.studentDetails.studentEnrollments[0]?.classesClassSection?.class?.name || '',
        classId: assessmentData.overallReport.studentDetails.studentEnrollments[0]?.classesClassSection?.class?.id,
        activitiesId: assessmentActivitiesData[0].id,
        activitiesGiven: assessmentActivitiesData[0].activitiesGiven,
        noOfSubmited: assessmentActivitiesData[0].activitiesSubmitted,
        remarks: assessmentActivitiesData[0].remarks,
    });
    const [overallAssessmentData, setOverAllAssessmentData] = useState([]);

    useEffect(() => {
        if (assessmentData) {
            const assessmentReportData = Object.values(assessmentData.overallReport.assessmentReport)

            const formatAssessmentReportData = assessmentReportData[0].map((item) => ({
                "id": item.id,
                "subject": item.subject.name,
                "subjectId": item.subject.id,
                "writtenMaxMarks": item.writtenMaxMarks,
                "writtenScoreMarks": item.writtenScoreMarks,
                "oralMaxMarks": item.oralMaxMarks,
                "oralScoreMarks": item.oralScoreMarks,
                "projectMaxMarks": item.projectMaxMarks,
                "projectScoreMarks": item.projectScoreMarks,
                "totalMaxMarks": item.totalMaxMarks,
                "totalScoreMarks": item.totalScoreMarks,
                "grade": item.grade,
                "remarks": item.remarks,
            }));
            const assessmentSkillData = Object.values(assessmentData.overallReport.skillDevelopmentReport)
            const formatAssessmentSkillData = assessmentSkillData[0].map((item) => ({
                id: item.id,
                skillId: item.assessmentSkillId,
                label: item.assessmentSkill.name,
                value: item.assessmentSkill.name,
                grade: item.grade
            }));
            setData(formatAssessmentReportData)
            setSkillData(formatAssessmentSkillData);
        }
    }, [assessmentData]);

    const franchiseeSchoolId = sessionStorage.getItem("franchiseeSchoolId");
    const FranchiseeSchoolId = Number(franchiseeSchoolId);
    const [formErrors, setFormErrors] = useState({
        studentName: false,
        assessment: false,
        class: false,
    });

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [skillData, setSkillData] = useState([]);
    const showSuccessMsg = useSelector(
        (state) => state.UpdateAssessmentReport.showSuccessMessage
    );
    const successMsg = useSelector(
        (state) => state.UpdateAssessmentReport.successMessage
    );

    const showErrorMsg = useSelector(
        (state) => state.UpdateAssessmentReport.showErrorMessage
    );

    const errorMsg = useSelector(
        (state) => state.UpdateAssessmentReport.errorMessage
    );

    const columns = [
        { field: "subject", header: "Subject" },
        {
            field: "writtenMaxMarks",
            header: (
                <span>
                    Written<br />
                    Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
                </span>
            ),
            inputType: "number",
            handleMarksChange: (e, row) => handleMarksChange(e, row, "writtenMaxMarks")
        },
        {
            field: "oralMaxMarks",
            header: (
                <span>
                    Orals<br />
                    Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
                </span>
            ),
            inputType: "number",
            handleMarksChange: (e, row) => handleMarksChange(e, row, "oralMaxMarks")
        },
        (formData.assessment === "SA2" || formData.assessment === "SA1") && {
            field: "projectMaxMarks",
            header: (
                <span>
                    Project<br />
                    Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
                </span>
            ),
            inputType: "number",
            handleMarksChange: (e, row) => handleMarksChange(e, row, "projectMaxMarks")
        },
        {
            field: "totalMaxMarks",
            header: (
                <span>
                    Total <span style={{ paddingRight: '10px' }}></span><br />
                    Max Marks
                </span>
            ),
            inputType: "number"
        },
        {
            field: "totalScoreMarks",
            header: (
                <span>
                    Total<br />
                    Scored
                </span>
            ),
            inputType: "number"
        },
        { field: "grade", header: "Grade" },
        { field: "remarks", header: "Remarks" },
    ];

    const columns1 = [
        { field: "value", header: "Skill" },
        { field: "grade", header: "Grade" },
        { field: "", header: "" },
    ];

    const assessmentSkillData =
        useSelector((state) => state.viewAssessment.data) || [];
    useEffect(() => {
        if (assessmentSkillData.length === 0) {
            dispatch(fetchAssessmentDataOnce());
        }
    }, [assessmentSkillData, dispatch]);

    const viewStudentData = useSelector((state) => state.viewStudent.data) || [];

    useEffect(() => {
        if (viewStudentData.length === 0) {
            dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
        }
    }, [viewStudentData, dispatch]);

    function getSubjectMarksReport(data) {
        const subjectsReport = [];
        const subjectMap = {}; // Map to track subjects by name for easier aggregation

        // Helper function to process assessments
        const processAssessment = (assessment, faKey) => {
            assessment.forEach(item => {
                const subject = item.subject;
                if (subject.name !== "Total") { // Ignore the "Total" entry
                    if (!subjectMap[subject.name]) {
                        // Initialize subject entry in the map
                        subjectMap[subject.name] = {
                            id: subject.id,
                            subject: subject.name,
                            fa1: { maxMarks: 0, scoredMarks: 0 },
                            fa2: { maxMarks: 0, scoredMarks: 0 }
                        };
                    }
                    // Update the relevant FA key
                    subjectMap[subject.name][faKey] = {
                        maxMarks: item.totalMaxMarks,
                        scoredMarks: item.totalScoreMarks
                    };
                }
            });
        };

        // Process FA1 and FA2
        if (data.overallReport.assessmentReport.FA1) {
            processAssessment(data.overallReport.assessmentReport.FA1, 'fa1');
        }
        if (data.overallReport.assessmentReport.FA2) {
            processAssessment(data.overallReport.assessmentReport.FA2, 'fa2');
        }

        // Convert the map to an array format
        for (const key in subjectMap) {
            subjectsReport.push(subjectMap[key]);
        }

        return subjectsReport;
    }

    const overallAssessment = async () => {
        const response = await fetch(`${API_PATH}/franchisee-school/${FranchiseeSchoolId}/assessments/${formData.studentId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token()}`,
            },
        });

        if (!response.ok) {
            throw new Error('Request failed');
        }

        const assessment_data = await response.json();

        setOverAllAssessmentData(getSubjectMarksReport(assessment_data));
    }

    useEffect(() => {
        overallAssessment()
    }, []);

    const renderGradeDropdown = (row) => {
        const handleGradeChange = (selectedGrade, row) => {
            setSkillData((prevData) => {
                const updatedData = prevData.map((data) =>
                    data.skillId === row.skillId ? { ...data, grade: selectedGrade.value } : data
                );
                return updatedData;
            });
        };
        const defaultGrade = { value: row?.grade, label: row?.grade }
        return (
            <TableCell key={`grade-${row.skillId}`}>
                <SelectDropdown
                    value={defaultGrade || row.grade?.value}
                    options={[
                        { value: "NA Not applicable", label: "NA Not applicable", id: 1 },
                        { value: "IR Improvement required", label: "IR Improvement required", id: 2 },
                        { value: "E-Excellent", label: "E-Excellent", id: 3 },
                        { value: "G Good", label: "G-Good", id: 4 },
                    ]}
                    onChange={(selectedGrade) => handleGradeChange(selectedGrade, row)}
                />
            </TableCell>
        );
    };

    const calculateGrade = (totalScoreMarks, totalMaxMarks) => {
        const percentage = (totalScoreMarks / totalMaxMarks) * 100;
        if (percentage >= 90 && percentage <= 100) {
            return "A+";
        } else if (percentage >= 75 && percentage <= 89) {
            return "A";
        } else if (percentage >= 56 && percentage <= 74) {
            return "B";
        } else if (percentage >= 35 && percentage <= 55) {
            return "C";
        } else if (percentage >= 0 && percentage < 35) {
            return "D";
        } else {
            return "Grade";
        }
    };

    const handleMarksChange = (e, row, field) => {
        let inputMarks;
        let updatedRow;
        const values = e.target.value
        if (field === "remarks") {
            updatedRow = { ...row, "remarks": e.target.value };
        } else if (values !== '') {
            inputMarks = Number(e.target.value);
            updatedRow = { ...row, [field]: inputMarks };
        } else {
            updatedRow = { ...row, [field]: '' };
        }

        const writtenMaxMarks = parseFloat(updatedRow.writtenMaxMarks) || 0;
        const writtenScoreMarks = parseFloat(updatedRow.writtenScoreMarks) || 0;
        const oralMaxMarks = parseFloat(updatedRow.oralMaxMarks) || 0;
        const oralScoreMarks = parseFloat(updatedRow.oralScoreMarks) || 0;
        const projectMaxMarks = parseFloat(updatedRow.projectMaxMarks) || 0;
        const projectScoreMarks = parseFloat(updatedRow.projectScoreMarks) || 0;

        if (writtenScoreMarks > writtenMaxMarks) {
            return;
        }

        if (oralScoreMarks > oralMaxMarks) {
            return;
        }

        if (projectScoreMarks > projectMaxMarks) {
            return;
        }

        const assessmentItem = overallAssessmentData.find(item => item.id === row.id);
        const fa1Ratio = (assessmentItem?.fa1?.scoredMarks / assessmentItem?.fa1?.maxMarks) || 0;
        const fa2Ratio = (assessmentItem?.fa2?.scoredMarks / assessmentItem?.fa2?.maxMarks) || 0;
        const assessmentFactor = formData.assessment === "SA1" || formData.assessment === "SA2" ? Math.ceil((fa1Ratio + fa2Ratio) * 5 / 2) : 0;

        const totalMaxMarks = writtenMaxMarks + oralMaxMarks + (formData.assessment === "SA1" || formData.assessment === "SA2" ? projectMaxMarks + 5 : 0);
        const totalScoreMarks = assessmentFactor + writtenScoreMarks + oralScoreMarks + (formData.assessment === "SA1" || formData.assessment === "SA2" ? projectScoreMarks : 0);
        const updatedRowWithCalculations = {
            ...updatedRow,
            totalMaxMarks,
            totalScoreMarks,
            grade: calculateGrade(totalScoreMarks, totalMaxMarks)
        };

        // Update the state with the updated row
        setData((prevData) => {
            // Update the specific row in the data
            const updatedData = prevData.map((rowData) =>
                rowData === row ? updatedRowWithCalculations : rowData
            );

            // Remove the "Total" row and recalculate totals
            const filteredData = updatedData.filter((row) => row.subject !== "Total");
            const updatedTotals = calculateTotals(filteredData);

            // Add the recalculated "Total" row back to the data
            return [...filteredData, updatedTotals];
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = skillData.map(({ skillId, grade, id }) => ({ skillId, grade, id }));
        const sendData = {
            activitiesAssessments: [
                {
                    id: formData.activitiesId,
                    activitiesGiven: formData.activitiesGiven,
                    activitiesSubmitted: formData.noOfSubmited,
                    remarks: formData.remarks,
                },
            ],
            studentId: formData.studentId,
            assessment: formData.assessment,
            subjectsAssessments: data.filter((row) => row.subject !== "Total").map((obj) => ({
                id: obj?.id,
                writtenMaxMarks: obj?.writtenMaxMarks || 0,
                writtenScoreMarks: obj?.writtenScoreMarks || 0,
                oralMaxMarks: obj?.oralMaxMarks || 0,
                oralScoreMarks: obj?.oralScoreMarks || 0,
                projectMaxMarks: obj?.projectMaxMarks || 0,
                projectScoreMarks: obj?.projectScoreMarks || 0,
                totalMaxMarks: obj?.totalMaxMarks || 0,
                totalScoreMarks: obj?.totalScoreMarks || 0,
                grade: obj?.grade || "",
                subjectId: obj?.subjectId || "",
                remarks: obj?.remarks || "",
            })),

            skillsAssessments: updatedData,

        };
        dispatch(UpdateAssessmentReportRequest(FranchiseeSchoolId, formData.studentId, sendData));
    };

    const handleClear = () => {
        setSkillData([]);
        setData([]);
        setFormData({
            studentNameId: null,
            assessmentId: null,
            class: "",
        });
        setDropDowns({});
        setValidated(false);
    };
    const handleCloseSuccessPopup = () => {
        dispatch(setShowSuccessMessage(false));
        handleClear();
        navigate('/viewAssessment')
    };

    const handleCloseErrorPopup = () => {
        dispatch(setShowErrorMessage(false));
    };


    const calculateTotalScoredMarks = (row) => {
        // Assuming written and oral scored marks are fields in your row data
        const writtenScored = parseFloat(row?.writtenMaxMarks) || 0;
        const oralScored = parseFloat(row?.oralMaxMarks) || 0;
        return writtenScored + oralScored;
    };
    const calculateTotalMaxMarks = (row) => {
        // Assuming written and oral max marks are fields in your row data
        const writtenMax = parseFloat(row?.writtenMaxMarks) || 0;
        const oralMax = parseFloat(row?.oralMaxMarks) || 0;
        return writtenMax + oralMax;
    };


    const calculateTotals = (data) => {
        const initialTotals = {
            writtenMaxMarks: 0,
            writtenMaxMarks: 0,
            writtenScoreMarks: 0,
            oralMaxMarks: 0,
            oralScoreMarks: 0,
            projectMaxMarks: 0,
            projectScoreMarks: 0,
            totalMaxMarks: 0,
            totalScoreMarks: 0,
        };

        const totals = data.reduce((totals, row) => {
            totals.writtenMaxMarks += parseFloat(row.writtenMaxMarks) || 0;
            totals.writtenScoreMarks += parseFloat(row.writtenScoreMarks) || 0;
            totals.oralMaxMarks += parseFloat(row.oralMaxMarks) || 0;
            totals.oralScoreMarks += parseFloat(row.oralScoreMarks) || 0;
            totals.projectMaxMarks += parseFloat(row.projectMaxMarks) || 0;
            totals.projectScoreMarks += parseFloat(row.projectScoreMarks) || 0;
            totals.totalMaxMarks += parseFloat(row.totalMaxMarks) || 0;
            totals.totalScoreMarks += parseFloat(row.totalScoreMarks) || 0;
            return totals;
        }, initialTotals);
        totals.subject = "Total"
        totals.grade = calculateGrade(totals.totalScoreMarks, totals.totalMaxMarks);
        return totals;

    };
    return (
        <>
            <div className="content-header"> Edit School Assessment</div>
            <Row>
                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                    <TextInputField
                        name="assessment"
                        placeholder="Assessment"
                        textType="Alphabet"
                        required
                        value={formData.assessment}
                        readOnly={true} // Make it read-only as per your requirement
                    />
                </Col>

                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                    <TextInputField
                        name="studentName"
                        placeholder="Student Name"
                        textType="Alphabet"
                        required
                        value={formData.studentName}
                        readOnly={true} // Make it read-only as per your requirement
                    />
                </Col>

                <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
                    <TextInputField
                        name="class"
                        placeholder="Class"
                        textType="Alphabet"
                        required
                        value={formData.class}
                        readOnly={true} // Make it read-only as per your requirement
                    />
                </Col>
            </Row>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} xs={12} md={6} lg={12} className="rowCss">
                        <>
                            <Table
                                data={data}
                                columns={columns}
                                handleMarksChange={handleMarksChange}

                            />
                            {/* <div className="totals-container">
                                <span className="total-item0">Total</span>
                                <div className="total-item1">{totals.writtenMaxMarks}</div>
                                <div className="total-item2">{totals.writtenScoreMarks}</div>
                                <div className="total-item3">{totals.oralMaxMarks}</div>
                                <div className="total-item4">{totals.oralScoreMarks}</div>
                                {["SA1", "SA2"].includes(formData.assessment) && (
                                    <div className="total-item5">{totals.projectMaxMarks}</div>
                                )}
                                {["SA1", "SA2"].includes(formData.assessment) && (
                                    <div className="total-item6">{totals.projectScoreMarks}</div>
                                )}
                                <div className="total-item7">{totals.totalMaxMarks}</div>
                                <div className="total-item8">{totals.totalScoreMarks}</div>
                                <div className="total-item9"><b>{totals.grade}</b></div>
                            </div> */}

                        </>
                    </Col>

                    <Col className="rowCss">
                        <div className="content-header ">Skill Development</div>
                        <SkillTable
                            data={skillData}
                            columns={columns1}
                            hover={true}
                            renderGradeDropdown={renderGradeDropdown}
                        />
                    </Col>

                    <div className="content-header mt-4">Activities/Project Create</div>
                    <Row className="mt-4" sm={12} xs={12} md={6} lg={12}>

                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                            <TextInputField
                                name="No Of Given"
                                placeholder="No of Given"
                                className="fcontrol"
                                text="Alphabet"
                                required
                                validated={validated}
                                value={formData.activitiesGiven || ""}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        activitiesGiven: event.target.value
                                    });
                                }}
                                feedbackMessage="Please enter no of given."
                                validationRegex={validationRegexPinNo}
                                validationErrorMessage="Please enter valid no of given."
                            />
                        </Col>

                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                            <TextInputField
                                name="No of Submited"
                                placeholder="No of Submited"
                                className="fcontrol"
                                text="Alphabet"
                                required
                                validated={validated}
                                value={formData.noOfSubmited || ""}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        noOfSubmited: event.target.value,
                                    });
                                }}
                                feedbackMessage="Please enter no of submited."
                                validationRegex={validationRegexPinNo}
                                validationErrorMessage="Please enter valid no of submited."
                            />
                        </Col>

                        <Col sm={6} xs={12} md={6} lg={3} className="rowCss" >
                            <TextInputField
                                name="Remarks"
                                placeholder="Remarks"
                                className="fcontrol"
                                textType="Alphabet"
                                required
                                validated={validated}
                                value={formData.remarks || ""}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        remarks: event.target.value,
                                    });
                                }}
                                feedbackMessage="Please enter Remarks."
                                validationRegex={validationRegexAddress}
                                validationErrorMessage="Please enter valid Remarks."
                            />
                        </Col>

                    </Row>
                </Row>

                <SuccessMessagePopup
                    show={showSuccessMsg}
                    onClose={handleCloseSuccessPopup}
                    successMessage={successMsg}
                />
                <ErrorMessagePopup
                    show={showErrorMsg}
                    onClose={handleCloseErrorPopup}
                    errorMessage={errorMsg}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1%",
                        marginTop: "5%",
                    }}
                >
                    <button
                        className="clear-button-figma-div"
                        type="button"
                        onClick={
                            handleClear
                        }
                    >
                        Clear
                    </button>
                    <button className="save-button-figma-div" type="submit">
                        Submit
                    </button>
                </div>
            </Form>
        </>
    );
};

export default EditAssessmentReport;
