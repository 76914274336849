import React, { useEffect, useState } from "react";
import { Form, Modal, Image, Dropdown } from "react-bootstrap";
import { updateEnquiry } from "../../EnquiryDetails/slice/enquiryFollowUpSlicer";
import { updateFranchise } from "../../Franchise/franchiseSlice/franchiseFollowUpUpdate";
import { updateVehicle } from "../../Administration/Transportation/addVehicleEditSlicer";
import { updateCreateLane } from "../../Transportation/createLineViewEditSlicer";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Select,
  ButtonBase,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import "../table/table.css";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Image as ImageView } from "@mui/icons-material";
import { CloudDownload } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Empty, Space } from "antd";
import { API_PATH } from "../../../Api/api";
import DueDatesModal from "../DueDatesModal";
import DueDatesModalWithAmount from "../DueDateModalWithAmount";
import FeeDueModel from "../feeDueModel";
// import PaymentTransaction from "../paymentTransaction";
import { useNavigate } from "react-router-dom";
import { fetchStudentFeeView } from "../../StudentDetails/slice/viewStudentFeeSlice";
import SelectDropdown from "../../SelectDropdown";
import PaymentTransaction from "../paymenttranction";
// import MediaRenderer from "../MediaRender";import { Button, Col, Empty, Form, Image, Row, Space } from "antd";
import ExcelJS from "exceljs";
import { FeePDFDocument } from "../feePDF";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { DownloadOutlined } from "@ant-design/icons";
import { updateExpensesType } from "../../pettyExpenses/slice/expensesTypePutSlicer";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateRevenueType } from "../../Revenue/slice/revenueTypeViewPutSlicer";
import { SelectingValuesDropDownField } from "../../../ReusableFields/DropDown/multipleSelectDropDown";
import SearchableDropDown from "../../../ReusableFields/DropDown/searchableDroDown";
import { fetchDataOnceProgram } from "../../programs/slice/viewProgramSlice";
import { fetchDataGetClassesByProgramId } from "../../programs/slice/getClassByProgramId";
import { fetchClassSectionsDataOnce } from "../../Franchise/slice/viewScetions";
import { token } from '../../../Api/token';
import { responseFormat } from "../../Auth/errorResponse";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const TableView = ({
  data = null,
  columns = [],
  statusOptions,
  handleUpdate,
  editedData,
  setEditedData,
  showEditModal,
  setShowEditModal,
  handleUpload,
  handleDocumentChange,
  uploadedDocs,
  isFeeHead = false,
  isExport = false,
  isExcel = false,
  excelColumns = null,
  excelFileName,
  isPdf = false,
  FeePDFDocument,
  pdfData,
  pdfFileName,
  handleDeleteClick,
  handleDeleteClickExpenses,
  handleRevenueEditSave,
  handleExpensesEditSave,
  expensesTypeEditModal,
  setExpensesTypeEditModal,
  setRevenueTypeEditModal,
  revenueTypeEditModal,
  handleDelete,
  colFields = [],
  handlePDF
}) => {
  const [selectedRowId, setSelectedRowId] = useState(null);
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formErrors, setFormErrors] = useState([{ id: 1, classId: false, programId: false }]);
  const [rowErrors, setRowErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showViewIconModal, setShowViewIconModal] = useState(false);
  const [selectedIconRow, setSelectedIconRow] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [studentShowViewIconModal, setStudentShowViewIconModal] = useState(false);
  const [studentSelectedIconRow, setStudentSelectedIconRow] = useState(null);
  const [showDocumentPopup1, setShowDocumentPopup1] = useState(false);
  const [employeeShowViewIconModal, setEmployeeShowViewIconModal] = useState(false);
  const [employeeSelectedIconRow, setEmployeeSelectedIconRow] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState(
    columns && columns[0] ? columns[0].field : ""
  );
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageAdmission, setErrorMessageAdmission] = useState("");
  const [statusValues, setStatusValues] = useState({});
  const [mindSetValues, setMindSetValues] = useState({});
  const [commentsValues, setCommentsValues] = useState({});
  const [franchiseValues, setFranchiseValues] = useState({});
  const [franchiseComments, setFranchiseCommentsValues] = useState({});
  const [showVehicleEditModal, setShowVehicleEditModal] = useState(false);
  // const [expensesTypeEditModal, setExpensesTypeEditModal] = useState(false);
  // const [revenueTypeEditModal, setRevenueTypeEditModal] = useState(false);
  const [showCreateLaneModal, setShowCreateLaneModal] = useState(false);
  const [showImageView, setShowImageView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showRajivStudentImageView, setShowRajivStudentImageView] =
    useState(false);
  const [dueDates, setDueDates] = useState([]);
  const [dueDatesWithAmount, setDueDatesWithAmount] = useState([]);
  const [showDueDates, setShowDueDates] = useState(false);
  const [showDueDatesWithAmount, setShowDueDatesWithAmount] = useState(false);
  const [dueFee, setDueFee] = useState([]);
  const [showDueFee, setShowDueFee] = useState(false);
  const [paymentTransaction, setPaymentTransaction] = useState([]);
  const [showPaymentTransaction, setShowPaymentTransaction] = useState(false);
  const [rajivStudentImageUrl, setRajivStudentImageUrl] = useState("");
  //   const [showRajivStudentImageView, setShowRajivStudentImageView] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showSingleDocumentPopup, setShowSingleDocumentPopup] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClassRow, setSelectedClassRow] = useState(null);
  const [classRows, setClassRows] = useState([{ id: 1, programId: null, classId: null, className: [], selectedSection: [] }]);
  const [showSectionDelete, setShowSectionDelete] = useState(false);
  const [oldClassData, setOldClassData] = useState({
    oldClassId: null,
    oldSectionId: []
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');

  const truncateString = (string) => {
    const formatted_string = string.map(classObj => classObj.name).join(', ');
    return formatted_string;
  };

  const classes = useSelector((state) => state.getClassesByProgramId.data) || [];
  const sections = useSelector((state) => state.viewSections.data) || [];
  const programsData = useSelector((state) => state.viewProgram.data) || [];
  useEffect(() => {
    dispatch(fetchDataOnceProgram());
  }, [dispatch]);

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleProgramChange = (id, selectedOption) => {

    dispatch(fetchDataGetClassesByProgramId(selectedOption.id))

    const updatedClassRows = classRows.map((row) => {
      if (row.id === id) {
        return { ...row, programId: selectedOption.id, classId: '', selectedSection: [] };
      }
      return row;
    });
    setSelectedProgram({ ...selectedProgram, name: selectedOption.value })
    setClassRows(updatedClassRows);
    const updatedErrors = [...formErrors]
    setFormErrors(updatedErrors);
  };

  const handleOpenModal = (rowId) => {
    setShowModalAdd(true);
  };

  const handleClassChange = (id, selectedOption) => {
    dispatch(fetchClassSectionsDataOnce(selectedOption.id))
    const updatedClassRows = classRows.map((row) => {
      if (row.id === id) {
        return { ...row, classId: selectedOption.id, className: selectedOption, selectedSection: [] };
      }
      return row;
    });
    setClassRows(updatedClassRows);
    setSelectedClassId(selectedOption.id);
    const updatedErrors = [...formErrors];
    setFormErrors(updatedErrors);
  };

  const handleSectionChange = (id, selectedOption) => {
    const selectedSectionIds = selectedOption.map((data) => data.id);
    const updatedClassRows = classRows.map((row) => {
      if (row.id === id) {
        return { ...row, selectedSection: selectedOption };
      }
      return row;
    });
    setClassRows(updatedClassRows);
    setSelectedSections(selectedSectionIds);
    const updatedErrors = [...formErrors];
    setFormErrors(updatedErrors);
  };

  const fetchClassData = async () => {
    try {
      const response = await fetch(`${API_PATH}/franchiseSchools`, {
        headers: {
          'Authorization': `Bearer ${token()}`, // Include your token here
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setClassRows(data); // Update the classRows state with the fetched data
    } catch (error) {
      console.error('Error fetching class data:', error);
    }
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    const newClasses = classRows.map((classRow) => ({
      programId: classRow.programId,
      classId: classRow.className.id,
      sectionIds: classRow.selectedSection.map(section => section.id),
    }));

    try {
      const promises = newClasses.map(async (newClass) => {
        const response = await fetch(`${API_PATH}/franchiseSchool/${selectedRowId}/class`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token()}`
          },
          body: JSON.stringify(newClass),
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Get the error message as text
          console.error("Error:", errorMessage);
          throw new Error(errorMessage || 'Network response was not ok');
        }

        const responseBody = await response.json();
        setPopupMessage(responseBody.message)
        return responseBody; // Parse as JSON or return an empty object
      });

      const addedClasses = await Promise.all(promises);
      setClassRows([...classRows, ...addedClasses]);
      setPopupType('success');
      setShowPopup(true);
      setShowModalAdd(false);
      setModalOpen(false);
      await fetchClassData();
    } catch (error) {
      const formattedError = responseFormat({ error: error.message || 'An error occurred' });
      setPopupMessage(formattedError);
      setPopupType('error');
      setShowPopup(true);
      console.error('Error adding classes:', formattedError);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  }
  const newData = {
    programId: selectedProgram,
    classId: selectedClassId,
    sections: selectedSections,
    subjects: selectedSubjects,
  };

  const handleSave = async (classInfo) => {

    const payload = {
      oldClass: {
        classId: oldClassData.oldClassId ? [oldClassData.oldClassId] : [],
        sectionId: oldClassData.oldSectionId.length > 0 ? oldClassData.oldSectionId : []
      },
      newClass: {
        classId: [selectedClassId],
        sectionId: selectedSections,
      }
    };
    try {
      const response = await fetch(`${API_PATH}/franchiseSchool/${selectedRowId}/class`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token()}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorMessage = await response.text(); // Get the error message as text
        console.error("Error:", errorMessage);
        throw new Error(errorMessage || 'Network response was not ok');
      }

      const updatedClassInfo = await response.json(); // Get updated class info

      // Update local state for the specific class row
      setClassRows(prevRows =>
        prevRows.map(row =>
          row.classId === selectedClassId ? { ...row, ...updatedClassInfo } : row
        )
      );

      setPopupMessage("Successfully Edited!")
      setPopupType('success');
      setShowPopup(true);
      setShowModalAdd(false);
      setModalOpen(false);
      setIsEditing(false)
      setShowSectionDelete(false)
      setShowOptions(false)
      await fetchClassData();
    } catch (error) {
      const formattedError = responseFormat({ error: error.message || 'An error occurred' });
      setPopupMessage(formattedError);
      setPopupType('error');
      setShowPopup(true);
      console.error('Error adding classes:', formattedError);
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const updatedClassRows = classRows.map((row) =>
      row.id === selectedClassRow.id ? { ...selectedClassRow, ...newData } : row
    );
    setClassRows(updatedClassRows);
    handleCloseModal();
  };

  const handleClassDelete = (classId) => {
    if (showSectionDelete === classId) {
      setShowSectionDelete(null);
    } else {
      setShowSectionDelete(classId);
    }
  };

  const handleSectionDelete = async (classId, sectionId) => {
    try {
      const response = await fetch(
        `${API_PATH}/franchiseSchool/${selectedRowId}/class?classId=${classId}&sectionId=${sectionId}`, // Adjust the URL as per your backend API
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token()}`
          },
        }
      );
      if (!response.ok) {
        const errorMessage = await response.text(); // Get the error message as text
        console.error("Error:", errorMessage);
        throw new Error(errorMessage || 'Network response was not ok');
      }

      const responseBody = await response.text(); // Get response text
      if (responseBody) {
        const data = JSON.parse(responseBody); // Now parse if there's a body
      }
      setClassRows(prevRows =>
        prevRows.map(classRow => {
          if (classRow.id === classId) {
            return {
              ...classRow,
              sections: classRow.sections.filter(section => section.id !== sectionId),
            };
          }
          return classRow;
        })
      );

      setPopupMessage("Successfully Deleted!")
      setPopupType('success');
      setShowPopup(true);
      setShowModalAdd(false);
      setModalOpen(false);
      setIsEditing(false)
      setShowOptions(false)
      setIsEditing(false)
      setShowSectionDelete(false)
      setShowOptions(false)
    } catch (error) {
      const formattedError = responseFormat({ error: error.message || 'An error occurred' });
      setPopupMessage(formattedError);
      setPopupType('error');
      setShowPopup(true);
      console.error('Error deleting classes:', formattedError);
    }
  };

  const handleCloseModal = () => {
    setShowModalAdd(false);
  };

  const getCaps = (head, field) => {
    if (head) {
      // Capitalize the first letter of each word
      return head
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      return field.toUpperCase();
    }
  };

  const handleEditClick = (row) => {
    setEditedData(row);
    setShowEditModal(true);
  };

  const handleVehicleEditClick = (row) => {
    setEditedData(row);
    setShowVehicleEditModal(true);
  };

  const handleCreateEditClick = (row) => {
    setEditedData(row);
    setShowCreateLaneModal(true);
  };

  const handleVehicleEditSave = () => {
    if (editedData && editedData.id) {
      const updatedData = {
        id: editedData.id,
        brand: editedData.brand,
        chasisNumber: editedData.chasisNumber,
        model: editedData.model,
        uploadDocuments: editedData.uploadedDocs,
        uploadDocuments: editedData.filePaths,
        vehicleNumber: editedData.vehicleNumber,
        vehicleEdit: editedData.vehicleEdit,
      };
      dispatch(updateVehicle(editedData.id, updatedData));
      setShowVehicleEditModal(false);
    } else {
      console.error("Row ID or edited data ID is missing.");
    }
  };

  const handleExpensesEditClick = (row) => {
    setEditedData(row);
    setExpensesTypeEditModal(true);
  };

  const handleRevenueEditClick = (row) => {
    setEditedData(row);
    setRevenueTypeEditModal(true);
  };

  // const handleRevenueEditSave = () => {
  //   if (editedData && editedData.id) {
  //     const updatedData = {
  //       id: editedData.id,
  //       name: editedData.name,
  //       revenueEdit: editedData.revenueEdit,
  //     };
  //     dispatch(updateRevenueType(editedData.id, updatedData));
  //     setRevenueTypeEditModal(false);
  //   } else {
  //     console.error("Row ID or edited data ID is missing.");
  //   }
  // };

  const updateHandleCreateLaneSave = () => {
    if (editedData && editedData.id) {
      const updatedData = {
        id: editedData.id,
        from: editedData.from,
        to: editedData.to,
        stops: editedData.stops,
        title: editedData.title,

      };
      dispatch(updateCreateLane(editedData.id, updatedData));
      setShowCreateLaneModal(false);
      const updatedDataWithoutSavedRow = data.filter(
        (existingRow) => existingRow.id !== editedData.id
      );
      setStatusValues(updatedDataWithoutSavedRow);
      setMindSetValues(updatedDataWithoutSavedRow);
    } else {
      console.error("Row ID or edited data ID is missing.");
    }
  };


  const handleEmployeeEnquiryDownload = (doc1) => {

    setSelectedDocument(doc1);
    setShowDocumentPopup1(true);

  };

  const handleDocumentIconClick2 = (docList) => {
    setDocuments(docList);
    setShowDocumentPopup(true);

  };

  const handleDocumentIconVehicleView = async (docList) => {
    try {
      if (docList && docList.length > 0) {
        const tokenId = token();
        const uploadDocuments = docList.length > 0 ? docList.map((e) => { return `fileId = ${e}` }).join('&&') : 'fileId=""';
        // const docsImgs = docList.length > 0 ? docList.map((e) => `fileId = ${ e }`).join('&&') : '';
        const response = await fetch(API_PATH + `/ uploadFiles /? ${uploadDocuments}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${tokenId}`
          }
        });
        const data = await response.json();

        const base64Documents = data.map(doc => ({
          type: doc.type,
          data: bufferToBase64(doc.data.data)
        }));

        setDocuments(base64Documents);
        setShowDocumentPopup(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDocumentIconClick1 = async (docList) => {

    try {
      if (docList && docList.length > 0) {
        const tokenId = token();
        const docsImgs =
          docList.length > 0
            ? docList
              .map((e) => {
                return `fileId = ${e}`;
              })
              .toString()
              .replaceAll(",", "&")
            : 'fileId=""';
        const response = await fetch(API_PATH + `/ uploadFiles /? ${docsImgs}`, {
          method: "GET",
          //   body: formData.profilePictureUrl,
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        });
        const data = await response.json();
        const base64String =
          data?.length > 0
            ? data?.map((e) => {
              const buffer = bufferToBase64(e.data.data);
              return { ...e, [e.data.data]: buffer };
            })
            : [];
        setDocuments(base64String);
        setShowSingleDocumentPopup(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDocumentIconClick = (docList) => {
    if (docList && docList.length > 0) {
      setDocuments(docList);
      setShowDocumentPopup(true);
    }
  };
  const handleDocumentIconClickBonafide = (docList) => {
    if (docList && docList.length > 0) {
      setDocuments(docList);
      setShowDocumentPopup(true);
    }
  };

  const mindSetOptions = [
    { value: "Positive", label: "Positive" },
    { value: "Negative", label: "Negative" },
    { value: "Follow-Up", label: "Follow-Up" },
  ];
  const handleEditSave = () => {
    if (editedData.id) {
      handleUpdate(editedData.id, editedData);
      setShowEditModal(false);
    }
  };

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setShowViewModal(true);
  };

  const handleViewIconClick = (row) => {
    setSelectedIconRow({ ...row, franchiseeEnquiryFollowUps: row.franchiseeEnquiryFollowUps });
    setShowViewIconModal(true);
  };
  const studentHandleViewIconClick = (row) => {
    setStudentSelectedIconRow({ ...row, studentEnquiryFollowUps: row.studentEnquiryFollowUps });
    setStudentShowViewIconModal(true);
  };
  const employeeHandleViewIconClick = (row) => {
    setEmployeeSelectedIconRow({ ...row, employeeEnquiryFollowUps: row.employeeEnquiryFollowUps });
    setEmployeeShowViewIconModal(true);
  };
  const downloadDocument = (url) => {
    const link = document.createElement("a");
    link.href = url;
    const parts = url.split("/");
    const filename = parts[parts.length - 1];
    link.download = filename;
    link.click();
  };

  const handleImageViewClick = (image) => {
    setSelectedImage(image);
    setShowImageView(true);
  };

  const bufferToBase64 = (buffer) => {
    return btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
  };
  const handleImageviewClick = async (docList) => {
    try {
      if (docList && docList.length > 0) {
        const tokenId = token();
        const cvUrl = docList.length > 0 ? docList.map((e) => { return `fileId = ${e}` }).join('&&') : 'fileId=""';
        // const docsImgs = docList.length > 0 ? docList.map((e) => `fileId = ${ e }`).join('&&') : '';
        const response = await fetch(API_PATH + `/ uploadFiles /? ${cvUrl}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${tokenId}`
          }
        });
        const data = await response.json();

        const base64Documents = data.map(doc => ({
          type: doc.type,
          data: bufferToBase64(doc.data.data)
        }));
        setDocuments(base64Documents);
        setShowDocumentPopup(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRajivImageViewClick = async (image) => {
    // setRajivStudentImageUrl(image);
    // setShowRajivStudentImageView(true);

    try {
      const tokenId = token();
      const response = await fetch(API_PATH + `/ uploadFiles /? fileId = ${image}`, {
        method: "GET",
        //   body: formData.profilePictureUrl,
        headers: {
          Authorization: `Bearer ${tokenId}`,
        },
      });
      const data = await response.json();

      const base64String = bufferToBase64(data[0].data.data);

      setShowImageView(true);
      setSelectedImage(image);
      setSelectedImage(base64String);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDueDatesShow = (Duedates) => {
    setDueDates(Duedates);
    setShowDueDates(true);
  };
  const handleDueDatesClose = () => {
    setShowDueDates(false);
  };
  const handleDueDatesShowWithAmount = (Duedates) => {
    setDueDatesWithAmount(Duedates);
    setShowDueDatesWithAmount(true);
  };
  const handleDueFee = (Duedates) => {
    setDueFee(Duedates);
    setShowDueFee(true);
  };
  const handleDueDatesCloseWithAmount = () => {
    setShowDueDatesWithAmount(false);
  };
  const handleCloseDueFee = () => {
    setShowDueFee(false);
  };
  const handlePaymentTransaction = (Duedates) => {
    setPaymentTransaction(Duedates);
    setShowPaymentTransaction(true);
  };

  const handleClosePaymentTransaction = () => {
    setShowPaymentTransaction(false);
  };

  const handleStatusChange = (rowId, event) => {
    setStatusValues((prevStatusValues) => ({
      ...prevStatusValues,
      [rowId]: event.target.value,
    }));
  };

  const handleFranchiseStatusChange = (rowId, event) => {
    setFranchiseValues((prevStatusValues) => ({
      ...prevStatusValues,
      [rowId]: event.target.value,
    }));
  };

  const handleMindSetStatusChange = (rowId, event) => {
    setMindSetValues((prevMindSetValues) => ({
      ...prevMindSetValues,
      [rowId]: event.target.value,
    }));
    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [rowId]: undefined,
    }));
  };

  const handleCommentsChange = (rowId, event) => {
    setCommentsValues((prevCommentsValues) => ({
      ...prevCommentsValues,
      [rowId]: event.target.value,
    }));
  };

  const handleFranchiseCommentsChange = (rowId, event) => {
    setFranchiseCommentsValues((prevCommentsValues) => ({
      ...prevCommentsValues,
      [rowId]: event.target.value,
    }));
    setRowErrors((prevErrors) => ({
      ...prevErrors,
      [rowId]: undefined,
    }));
  };

  const saveHandle = async (row) => {
    setLoading(true);
    const rowId = row.id;
    if (rowId) {
      const mindSetValue = mindSetValues[rowId];
      const description = commentsValues[rowId];

      if (!mindSetValue || !description) {
        const errorMsg = "Please fill in both mind set and description.";
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: errorMsg }));
        setLoading(false);
        return;
      }
      try {
        setLoadingStates((prevStates) => ({ ...prevStates, [rowId]: true }));
        const updatedData = {
          // id: row.id,
          date: row.date,
          dateOfBirth: row.dateOfBirth,
          fatherName: row.fatherName,
          motherName: row.motherName,
          address: row.address,
          studentName: row.studentName,
          contactNumber: row.contactNumber,
          timingId: row.timingId,
          dayFrequencyId: row.dayFrequencyId,
          programId: row.programId,
          mindSet: row.mindSet,
          reference: row.reference,
          classId: row.classId,
          mindSet: mindSetValues[row.id],
          description: commentsValues[row.id],
        };

        await dispatch(updateEnquiry(row.id, updatedData));
        const updatedDataWithoutSavedRow = data.filter(
          (existingRow) => existingRow.id !== row.id
        );
        setStatusValues(updatedDataWithoutSavedRow);
        setMindSetValues(updatedDataWithoutSavedRow);
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: undefined }));
      } catch (error) {
        console.error("Error saving data:", error);
        const errorMsg = "An error occured while saving. Please try again.";
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: errorMsg }));
      } finally {
        setLoadingStates((prevStates) => ({ ...prevStates, [rowId]: false }));
      }
    }
  };

  const franchiseSaveHandle = async (row) => {
    setLoading(true);
    const rowId = row.id;
    if (rowId) {
      const statusValue = franchiseValues[rowId];
      const commentsValue = franchiseComments[rowId];

      // Add validation checks for empty status or comments
      if (!statusValue || !commentsValue) {
        const errorMsg = "Please fill in both Status and Comments fields.";
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: errorMsg }));
        setLoading(false);
        return;
      }
      try {
        setLoadingStates((prevStates) => ({ ...prevStates, [rowId]: true }));
        const updatedData = {
          // id: row.id,
          date: row.date,
          occupation: row.occupation,
          address: row.address,
          cityId: row.cityId,
          contactNumber: row.contactNumber,
          email: row.email,
          areaId: row.areaId,
          investmentCapacity: row.investmentCapacity,
          programId: row.programId,
          reference: row.reference,
          status: franchiseValues[row.id],
          comments: franchiseComments[row.id],
          isActive: false,
        };
        await dispatch(updateFranchise(row.id, updatedData));

        const updatedDataWithoutSavedRow = data.filter(
          (existingRow) => existingRow.id !== rowId
        );
        setFranchiseValues(updatedDataWithoutSavedRow);
        setMindSetValues(updatedDataWithoutSavedRow);
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: undefined }));
      } catch (error) {
        console.error("Error saving data:", error);
        const errorMsg = "An error occurred while saving. Please try again.";
        setRowErrors((prevErrors) => ({ ...prevErrors, [rowId]: errorMsg }));
      } finally {
        setLoadingStates((prevStates) => ({ ...prevStates, [rowId]: false }));
      }
    }
  };

  const handleCloseDocumentPopup = () => {
    setShowDocumentPopup(false);
  };

  const handleSortClick = (column) => {
    if (column === "edit") {
      return;
    }

    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const navigate = useNavigate();


  const navigateToRepaymentStudentFee = (id) => {
    navigate(`/dueFee/${id}`);

  };

  let filteredData = [];

  // Check if data is an array
  if (Array.isArray(data)) {
    // Use the filter method on data
    filteredData = data.filter((row) => {
      const searchValue = searchQuery.toLowerCase();

      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        const columnValue = row[column.field];

        if (typeof columnValue === "string") {
          if (columnValue.toLowerCase().includes(searchValue)) {
            return true;
          }
        } else if (typeof columnValue === "number") {
          if (columnValue.toString().includes(searchValue)) {
            return true;
          }
        }
      }

      return false;
    });
  } else {
    console.error('Data is not an array.');
  }

  const sortedData = filteredData.slice().sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    // Check if the value is a date in DD-MM-YYYY format
    const isDate = (val) => /\d{2}-\d{2}-\d{4}/.test(val);

    if (typeof valueA === "string" && typeof valueB === "string") {
      if (isDate(valueA) && isDate(valueB)) {
        // Split and convert the date strings into Date objects
        const [dayA, monthA, yearA] = valueA.split("-").map(Number);
        const [dayB, monthB, yearB] = valueB.split("-").map(Number);

        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);

        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }

      // For other strings, use localeCompare
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    } else {
      // For numbers, directly compare them
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    }
  });


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const filteredColumns = columns.filter(
    (col) => !["id", "view", "edit", "delete"].includes(col.field)
  );

  const renderCommentInput = () => {
    return <input placeholder="add comments" />;
  };

  const renderAction = () => {
    return <button className="table-button">Submit</button>;
  };
  const options = [
    { value: "10:30", label: "10:30" },
    { value: "12:30", label: "12:30" },
    { value: "4:30", label: "4:30" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const renderFoodDescription = () => {
    return <input placeholder="Description" />;
  };


  const handleDownloadExcel = async () => {
    if (!sortedData || sortedData.length === 0) {
      // No data found, so do nothing
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    const columnsToUse = (excelColumns && excelColumns.length > 0) ? excelColumns : columns;

    // Map the selected columns to the format needed for columnHeaders
    const columnHeaders = columnsToUse.map(column => ({
      header: column.header,
      key: column.field
    }));
    worksheet.columns = columnHeaders;

    // Add the data to the worksheet
    sortedData.forEach((item) => {
      const rowData = {};
      columnsToUse.forEach(column => {
        rowData[column.field] = item[column.field];
      });
      worksheet.addRow(rowData);
    });

    // Create a buffer containing the Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${excelFileName}`;
    a.click();

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };
  const renderFoodTime = () => {
    return (
      <Select
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        className="foodTime_dropdown"
        placeholder="Select Time"
      >
        <MenuItem value="On Hold">10:30</MenuItem>
        <MenuItem value="Not Interested">12:30</MenuItem>
        <MenuItem value="interested">04:30</MenuItem>
      </Select>
    );
  };

  const renderRowColor = (backgroundColor) => {
    return backgroundColor;
  };

  const [singleDoc, setSingleDoc] = useState([]);

  const handleSingleCloseDocumentPopup = () => {
    setShowSingleDocumentPopup(false);
  };
  const handleSingleDoc = (doc) => {
    setSingleDoc([doc]);
    setShowSingleDocumentPopup(true);
  };

  const docsPathsNew =
    singleDoc && singleDoc.map((docPath) => docPath.replace(/\\/g, "/"));

  const modifiedPaths =
    docsPathsNew &&
    docsPathsNew.map((docPath) => {
      const fullPath = docPath;
      const pathParts = fullPath.split("kidhood-images-and-docs");
      const docsPaths = pathParts[1];
      return docsPaths;
    });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = (imagePath) => {
    setPreviewImage(imagePath);
    setPreviewVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onDownload = () => {
    fetch(previewImage)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const handleClassesEyeClick = (row) => {
    setSelectedRowId(row.id)
    setSelectedClass(row);
    setModalOpen(true);
  };

  const studentData = [
    {
      "id": 1,
      "name": "sam"
    },
    {
      "id": 2,
      "name": "leo"
    }
  ]

  const handleDownloadPdf = async (data, DocumentComponent) => {
    const pdfContent = <DocumentComponent data={data} />;

    try {
      const pdfBlob = await pdf(pdfContent).toBlob();
      if (!pdfBlob) {
        console.error("PDF NOT Converting");
        return;
      }

      const a = document.createElement('a');
      a.href = URL.createObjectURL(pdfBlob);
      a.download = pdfFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handleExportChange = (event) => {
    if (event.target.value === 'PDF') {
      handleDownloadPdf(pdfData, FeePDFDocument);
    } else if (event.target.value === 'Excel') {
      handleDownloadExcel();
    }
  };
  const handleDownload = (imageURL) => {
    // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'document.jpg'; // You can customize the downloaded file name here
    link.click();
  };
  const handleDownload1 = (selectedDocument) => {
    // You can implement the download logic here, e.g., using the "download" attribute of an anchor tag.
    const link = document.createElement('a');
    link.href = selectedDocument;
    link.download = 'document.pdf'; // You can customize the downloaded file name here
    link.click();
  };

  const handleCloseDocumentPopup1 = () => {

    setShowDocumentPopup1(false);
  };

  // const [isEditing, setIsEditing] = useState(null); // Tracks the row being edited
  const [editableData, setEditableData] = useState({}); // Holds data for the currently edited row

  const handleClassEdit = (classInfo) => {
    setIsEditing(classInfo);
    // setSelectedRowId(classInfo.id)
    setOldClassData({
      oldClassId: classInfo.classId || null, // Ensure oldClassId is properly captured
      oldSectionId: classInfo.sections?.map(section => section.id) || [] // Capture old section IDs
    });
  };
  const [showOptions, setShowOptions] = useState(false);
  const handleClassCancel = () => {
    setIsEditing(null);  // Exit edit mode without saving changes
  };

  const handleShowOptions = (event, classInfo) => {
    event.stopPropagation(); // Prevent event bubbling
    setShowOptions(showOptions === classInfo ? null : classInfo); // Toggle options
  };




  return (
    <div className="table-Maindiv">
      <TextField
        placeholder="Search"
        variant="standard"
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: "10px" }}
      />
      {isExport ? (
        <select onChange={handleExportChange} defaultValue="" style={{
          backgroundColor: "#006AB0", color: "white", borderRadius: 5, position: "absolute",
          top: 90,
          right: 50,
        }}>
          <option  >Export</option>
          {isPdf && <option value="PDF">Pdf</option>}
          {isExcel && <option value="Excel">Excel</option>}
        </select>
      ) : null}
      {isFeeHead ? (
        <SelectDropdown
          isSearchable
          required
          value={"sdfgrfd"}
          options={studentData.map((option, index) => ({
            id: option.id,
            value: option.name,
            label: option.name,
          }))}

          placeholder="Student Name"
          feedbackMessage="Please select student name."
        />
      ) : null}
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((head) => (
                  <TableCell
                    sx={{
                      backgroundColor: "#E2E8F0",
                      color: "#464E5F",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      padding: "8px",
                    }}
                    key={head?.field}
                    className="tablehead"
                  >
                    <TableSortLabel
                      active={sortColumn === head?.field}
                      direction={sortOrder}
                      onClick={() => handleSortClick(head?.field)}
                    >
                      {(head?.field, head?.header)}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {currentItems.map((row, rowIndex) => (
              <TableRow
                key={`${row.id} - ${rowIndex}`}
                sx={{ backgroundColor: renderRowColor(row.backgroundColor) }}
              >
                {columns.map((col) => (
                  <TableCell key={col.field} className="tablecell">
                    {
                      // col.field === "index" ? (
                      //   absoluteIndexOfRow(rowIndex)
                      // ) :
                      col.field === "repayment" ? (
                        !row.hasFullyPaid ? (
                          <button
                            onClick={() => navigateToRepaymentStudentFee(row.id)}
                            style={{
                              backgroundColor: "#112F6E",
                              color: "white",
                              borderRadius: "10px",
                              width: "90 px",
                              height: "30px",
                              fontSize: "15px"
                            }}
                            disabled={row.hasFullyPaid}
                          >

                            {loadingStates[row.id] ? "Saving..." : "Repayment"}
                          </button>
                        ) : "Fully Paid"
                      ) :
                        col.field === "view" ? (
                          <button onClick={() => handleViewClick(row)}>
                            View
                          </button>
                        ) : col.field === "save" ? (
                          <Button
                            onClick={() => saveHandle(row)}
                            style={{
                              backgroundColor: "#112F6E",
                              color: "white",
                              borderRadius: "10px",
                            }}
                            disabled={loadingStates[row.id]}
                          >
                            {loadingStates[row.id] ? "Saving..." : "Save"}
                          </Button>
                        ) : col.field === "franchiseSave" ? (
                          <Button
                            onClick={() => franchiseSaveHandle(row)}
                            style={{
                              backgroundColor: "#112F6E",
                              color: "white",
                              borderRadius: "10px",
                            }}
                            disabled={loadingStates[row.id]}
                          >
                            {" "}
                            {loadingStates[row.id] ? "Saving..." : "Save"}
                          </Button>
                        ) : col.field === "mindSetDropdown" ? (
                          <Select
                            value={mindSetValues[row.id] || "Select dropdown"}
                            onChange={(e) => handleMindSetStatusChange(row.id, e)}
                            options={mindSetOptions}
                            sx={{ minWidth: "150px" }}
                            placeholder="Select Mind Set"
                          >
                            {mindSetOptions &&
                              mindSetOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : col.field === "statusDropdown" ? (
                          <>
                            <Select
                              value={statusValues[row.id] || "select status"}
                              onChange={(e) => {
                                handleStatusChange(row.id, e);
                                handleFranchiseStatusChange(row.id, e);
                              }}
                              sx={{ minWidth: "150px" }}
                              placeholder="select status"
                            >
                              {statusOptions &&
                                statusOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </>
                        ) : col.field === "comments" ? (
                          <>
                            <textarea
                              value={commentsValues[row.id] || ""}
                              onChange={(e) => handleCommentsChange(row.id, e)}
                            />
                            {rowErrors[row.id] && (
                              <div className="error-message">
                                {rowErrors[row.id]}
                              </div>
                            )}
                          </>
                        ) : col.field === "franchiseComments" ? (
                          <>
                            <textarea
                              value={franchiseComments[row.id] || ""}
                              onChange={(e) =>
                                handleFranchiseCommentsChange(row.id, e)
                              }
                            />
                            {rowErrors[row.id] && (
                              <div className="error-message">
                                {rowErrors[row.id]}
                              </div>
                            )}
                          </>
                        ) : col.field === "image" ? (
                          <IconButton
                            onClick={() => handleImageViewClick(row.image)}
                            color="primary"
                            size="small"
                          >
                            <ImageView />
                          </IconButton>
                        ) : col.field === "pictures" ? (
                          <IconButton
                            onClick={() => handleImageViewClick(row.pictures)}
                            color="primary"
                            size="small"
                          >
                            <ImageView />
                          </IconButton>
                        )
                          // : col.field === "cvUrl" ? (
                          //   <IconButton
                          //     onClick={() => {

                          //       handleDocumentIconClick2(row.cvUrl)
                          //     }}
                          //     color="primary"
                          //     size="small"
                          //   >
                          //     <UploadFileIcon />
                          //   </IconButton>
                          // )
                          : col.field === "cvUrl" ? (
                            <IconButton
                              onClick={() => {

                                handleEmployeeEnquiryDownload(row.cvUrl)
                              }}
                              color="primary"
                              size="small"
                            >
                              <UploadFileIcon />
                            </IconButton>
                          ) : col.field === "uploadDocuments" ? (
                            <IconButton
                              onClick={() => {

                                handleDocumentIconVehicleView(row.uploadDocuments)
                              }}
                              color="primary"
                              size="small"
                            >
                              <UploadFileIcon />
                            </IconButton>
                          )
                            : col.field === "profilePictureUrl" ? (
                              <IconButton
                                onClick={() =>
                                  handleRajivImageViewClick(row.profilePictureUrl)
                                }
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "documentUrls" ? (
                              <IconButton
                                onClick={() =>
                                  handleDocumentIconClick(row.documentUrls)
                                }
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "address" ? (
                              <Tooltip
                                title={
                                  Array.isArray(row[col.field])
                                    ? row[col.field][0].address
                                    : row[col.field]
                                }
                              >
                                <span>
                                  {Array.isArray(row[col.field])
                                    ? row[col.field][0].address.substring(0, 10) +
                                    (row[col.field][0].address.length > 10
                                      ? "..."
                                      : "")
                                    : row[col.field].substring(0, 10) +
                                    (row[col.field].length > 10 ? "..." : "")}
                                </span>
                              </Tooltip>
                            ) : col.field === "fee_classes" ? (
                              <Tooltip
                                title={
                                  Array.isArray(row[col.field])
                                    ? row[col.field][0].fee_classes
                                    : row[col.field]
                                }
                              >
                                <span>
                                  {Array.isArray(row[col.field])
                                    ? row[col.field][0].fee_classes.substring(0, 15) +
                                    (row[col.field][0].fee_classes.length > 15
                                      ? "..."
                                      : "")
                                    : row[col.field].substring(0, 15) +
                                    (row[col.field].length > 15 ? "..." : "")}
                                </span>
                              </Tooltip>
                            ) : col.field === "classes" ? (
                              <IconButton onClick={() => handleClassesEyeClick(row)}>
                                <VisibilityIcon />
                              </IconButton>
                            ) : col.field === "previousBonafideCertifiedUrl" ? (
                              <IconButton
                                onClick={() =>
                                  handleSingleDoc(row.previousBonafideCertifiedUrl)
                                }
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "previousTransferCertificateUrl" ? (
                              <IconButton
                                onClick={() =>
                                  handleSingleDoc(row.previousTransferCertificateUrl)
                                }
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "previousReportCardUrl" ? (
                              <IconButton
                                onClick={() =>
                                  handleSingleDoc(row.previousReportCardUrl)
                                }
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "edit" ? (
                              <IconButton
                                onClick={() => {
                                  handleEditClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            ) : col.field === "vehicleEdit" ? (
                              <IconButton
                                onClick={() => {
                                  handleVehicleEditClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            ) : col.field === "expensesEdit" ? (
                              <IconButton
                                onClick={() => {
                                  handleExpensesEditClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            ) : col.field === "revenueEdit" ? (
                              <IconButton
                                onClick={() => {
                                  handleRevenueEditClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            ) : col.field === "revenueDelete" ? (
                              <IconButton
                                onClick={() => handleDeleteClick(row.id)}
                                color="primary"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : col.field === "expensesDelete" ? (
                              <IconButton
                                onClick={() => handleDeleteClickExpenses(row.id)}
                                color="primary"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : col.field === "createLaneEdit" ? (
                              <IconButton
                                onClick={() => {
                                  handleCreateEditClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            ) : col.field === "view" ? (
                              <IconButton
                                onClick={() => {
                                  handleViewClick(row);
                                }}
                                color="primary"
                                size="small"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : col.field === "image" ? (
                              <IconButton
                                onClick={() => handleImageViewClick(row.image)}
                                color="primary"
                                size="small"
                              >
                                <ImageView />
                              </IconButton>
                            ) : col.field === "documents" ? (
                              <IconButton
                                onClick={() => handleDocumentIconClick(row.documents)}
                                color="primary"
                                size="small"
                              >
                                <UploadFileIcon />
                              </IconButton>
                            ) : col.field === "DueDatesView" ? (
                              <IconButton
                                onClick={() => handleDueDatesShow(row.dueDates)}
                                color="primary"
                                size="small"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : col.field === "DueDatesViewWithAmount" ? (
                              <IconButton
                                onClick={() =>
                                  handleDueDatesShowWithAmount(row.dueDates)
                                }
                                color="primary"
                                size="small"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : col.field === "feeTerms" ? (
                              <IconButton
                                onClick={() =>
                                  handleDueFee(row.feeTerms)
                                }
                                color="primary"
                                size="small"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )
                              : col.field === "transactions" ? (
                                <IconButton
                                  onClick={() =>
                                    handlePaymentTransaction(row.transactions)
                                  }
                                  color="primary"
                                  size="small"
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )
                                : col.field === "uploadDocuments" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleDocumentIconClick(row.uploadDocuments)
                                    }
                                    color="primary"
                                    size="small"
                                  >
                                    <UploadFileIcon />
                                  </IconButton>

                                ) : col.field === "FollowUps" ? (
                                  <IconButton
                                    onClick={() => {
                                      handleViewIconClick(row);
                                    }}
                                    color="primary"
                                    size="small"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                ) : col.field === "StudentFollowUps" ? (
                                  <IconButton
                                    onClick={() => {
                                      studentHandleViewIconClick(row);
                                    }}
                                    color="primary"
                                    size="small"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                ) : col.field === "remarks" ? (
                                  col.render(row)
                                ) : col.field === "commentInput" ? (
                                  // renderCommentInput(row)
                                  col.render(row)
                                ) : col.field === "action_Submit" ? (
                                  renderAction(row)
                                ) : col.field === "foodDescription" ? (
                                  renderFoodDescription(row)
                                ) : col.field === "foodTime" ? (
                                  renderFoodTime(row)
                                ) : col.field === "checkBox" ? (
                                  col.render(row)
                                ) : col.field === 'hasFullyPaid' ? (
                                  <span>{row[col.field] ? "Yes" : "No"}</span>
                                ) : col.field === 'paidDate' ? (
                                  col.render(row)
                                ) : col.field === "comments_send" ? (
                                  // renderAction(row)
                                  col.render(row)
                                ) : col.field === "hasParentAttend" ? (
                                  col.render(row)
                                ) : col.field === "delete" ? (
                                  <IconButton
                                    onClick={() => handleDelete(row.id)}
                                    color="primary"
                                    size="small"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : col.field === "pdfGenerate" ? (
                                  <IconButton
                                    onClick={() => handlePDF(row.id)}
                                    color="primary"
                                    size="small"
                                  >
                                    <PictureAsPdfIcon />
                                  </IconButton>
                                ) : col.render ? (
                                  col.render(row)
                                ) : (
                                  row[col.field]
                                )
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data ? null : <p>No data found</p>}

      <Pagination
        className="pagination-css"
        count={Math.ceil(sortedData.length / itemsPerPage)}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
      />

      <Modal
        show={showImageView}
        onHide={() => setShowImageView(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <object
              data={`data: image / png; base64, ${selectedImage} `}
              // type="application/pdf"
              type="image/jpeg"
              width="100%"
              height="100%"
            >
              <p>Unable to display Profile Picture!</p>
            </object>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowImageView(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSingleDocumentPopup}
        onHide={handleSingleCloseDocumentPopup}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {documents && documents.length > 0 ? (
            <ul>
              {documents.map((document, index) => {
                return (
                  <li key={index} style={{ margin: "5px" }}>
                    <object
                      //src={`data:${ src.type }; base64, ${ btoa(String.fromCharCode.apply(null, new Uint8Array(src.data.data))) } `}
                      data={`data:${document.type}; base64, ${btoa(
                        String.fromCharCode.apply(
                          null,
                          new Uint8Array(document.data.data)
                        )
                      )
                        } `}
                      //data={require(`../../ kidhood - images - and - docs${ document } `)}
                      //src={document}
                      //data={`data: image / png; base64, ${ document.data.data } `}
                      //type="application/pdf"
                      type={`${document.type} `}
                      width="200"
                      height="200"
                    >
                      <p>
                        Unable to display Image/PDF. Please download it instead.
                      </p>
                    </object>
                    <Button
                      onClick={() => {
                        downloadDocument(require(`../../ kidhood - images - and - docs${document} `))
                        downloadDocument(document);
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={<CloudDownload />}
                      size="small"
                      style={{ marginLeft: "10px" }}
                    >
                      Download
                    </Button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No documents available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSingleCloseDocumentPopup}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDocumentPopup}
        onHide={handleCloseDocumentPopup}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Documents</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {documents && documents.length > 0 ? (
            <ul>

              {documents.map((document, index) => (
                <li key={index} style={{ margin: "5px" }}>
                  <object
                    data={`data:${document.type}; base64, ${document.data} `}
                    type={`${document.type} `}
                    width="200"
                    height="200"
                  >
                    <p>Unable to display Image/PDF. Please download it instead.</p>
                  </object>
                  <Button
                    onClick={() => {
                      downloadDocument(document);
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownload />}
                    size="small"
                    style={{ marginLeft: "10px" }}
                  >
                    Download
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No documents available</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDocumentPopup}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {selectedRow &&
              filteredColumns.map((col) => (
                <Form.Group key={col.field} className="mb-3">
                  <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                  <Form.Control
                    type="text"
                    value={selectedRow[col.field]}
                    readOnly
                  />
                </Form.Group>
              ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowViewModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showViewIconModal}
        onHide={() => setShowViewIconModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Follow Ups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="table-bordered yash-table" >
            <thead>
              <tr>
                <th>Status</th>
                <th>Comments</th>
                <th>Updated&nbsp;At</th>
              </tr>
            </thead>
            <tbody>
              {selectedIconRow &&
                selectedIconRow.franchiseeEnquiryFollowUps.map((followUp, index) => {
                  // Convert updatedAt string to a Date object
                  const updatedAtDate = new Date(followUp.updatedAt);

                  // Extract date components
                  const day = updatedAtDate.getDate().toString().padStart(2, '0');
                  const month = updatedAtDate.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
                  const year = updatedAtDate.getFullYear();

                  // Extract time components
                  const hours = updatedAtDate.getHours().toString().padStart(2, '0');
                  const minutes = updatedAtDate.getMinutes().toString().padStart(2, '0');
                  const period = hours >= 12 ? 'PM' : 'AM';
                  const formattedHours = (hours % 12) || 12; // Convert to 12-hour format

                  // Combine date and time components with line break
                  const formattedDateTime = `${day} -${month} -${year} <br />${formattedHours}:${minutes} ${period} `;

                  return (
                    <tr key={index}>
                      <td>{followUp.status}</td>
                      <td>{followUp.description}</td>
                      <td dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                    </tr>
                  );
                })}
            </tbody>

          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowViewIconModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={studentShowViewIconModal}
        onHide={() => setStudentShowViewIconModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Follow Ups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="table-bordered yash-table" >
            <thead>
              <tr>
                <th>Mind Set</th>
                <th>Remarks</th>
                <th>Updated&nbsp;At</th>
              </tr>
            </thead>
            <tbody>
              {selectedIconRow &&
                selectedIconRow.franchiseeEnquiryFollowUps.map((followUp, index) => {
                  // Convert updatedAt string to a Date object
                  const updatedAtDate = new Date(followUp.updatedAt);

                  // Extract date components
                  const day = updatedAtDate.getDate().toString().padStart(2, '0');
                  const month = updatedAtDate.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
                  const year = updatedAtDate.getFullYear();

                  // Extract time components
                  const hours = updatedAtDate.getHours().toString().padStart(2, '0');
                  const minutes = updatedAtDate.getMinutes().toString().padStart(2, '0');
                  const period = hours >= 12 ? 'PM' : 'AM';
                  const formattedHours = (hours % 12) || 12; // Convert to 12-hour format

                  // Combine date and time components with line break
                  const formattedDateTime = `${day} -${month} -${year} <br />${formattedHours}:${minutes} ${period} `;

                  return (
                    <tr key={index}>
                      <td>{followUp.status}</td>
                      <td>{followUp.description}</td>
                      <td dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                    </tr>
                  );
                })}
            </tbody>

          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setStudentShowViewIconModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={employeeShowViewIconModal}
        onHide={() => setEmployeeShowViewIconModal(false)}
        className="modal"
      > 
        <Modal.Header closeButton>
          <Modal.Title>Employee Follow Ups</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="table-bordered yash-table" >
            <thead>
              <tr>
                <th>Remarks</th>
                <th>Updated&nbsp;At</th>
                <th>Role&nbsp;Confirmation</th>
                <th>Date&nbsp;Of&nbsp;Joining</th>

              </tr>
            </thead>
            <tbody>
              {employeeSelectedIconRow &&
                employeeSelectedIconRow.employeeEnquiryFollowUps.map((followUp, index) => {
                  const updatedAtDate = new Date(followUp.updatedAt);

                  const day = updatedAtDate.getDate().toString().padStart(2, '0');
                  const month = (updatedAtDate.getMonth() + 1).toString().padStart(2, '0');
                  const year = updatedAtDate.getFullYear();

                  const hours = updatedAtDate.getHours().toString().padStart(2, '0');
                  const minutes = updatedAtDate.getMinutes().toString().padStart(2, '0');
                  const period = hours >= 12 ? 'PM' : 'AM';
                  const formattedHours = (hours % 12) || 12; // Convert to 12-hour format

                  const formattedDateTime = `${ day } -${ month } -${ year } <br />${ formattedHours }:${ minutes } ${ period } `;

                  return (
                    <tr key={index}>
                      <td>{followUp.remark ? followUp.remark : "N/A"}</td>
                      <td>{followUp.roleConfirmation ? followUp.roleConfirmation : "N/A"}</td>
                      <td>{followUp.expectedDateOfJoining ? followUp.expectedDateOfJoining : "N/A"}</td>
                      <td dangerouslySetInnerHTML={{ __html: formattedDateTime }}></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEmployeeShowViewIconModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}


      <Modal
        show={showVehicleEditModal}
        onHide={() => setShowVehicleEditModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData
              ? filteredColumns.map((col) =>
                col.field !== "index" && col.field !== "vehicleEdit" ? (
                  <Form.Group key={col.field} className="mb-3">
                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                    {col.field === "uploadDocuments" ? (
                      <Form.Control
                        type="file"
                        onChange={handleDocumentChange}
                        multiple
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        onChange={(e) => {
                          setEditedData({
                            ...editedData,
                            [col.field]: e.target.value,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                ) : null
              )
              : ""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleVehicleEditSave}
            style={{
              color: "white",
              backgroundColor: "#007bff",
              fontSize: "12px",
              borderRadius: "20px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={expensesTypeEditModal}
        onHide={() => setExpensesTypeEditModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData
              ? filteredColumns
                .filter(col => col.field !== "index" && col.field !== "expensesEdit" && col.field !== "expensesDelete") // Exclude unwanted fields
                .map(col => (
                  <Form.Group key={col.field} className="mb-3">
                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>

                    <Form.Control
                      type="text"
                      value={editedData[col.field]}
                      onChange={(e) => {
                        setEditedData({
                          ...editedData,
                          [col.field]: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                ))
              : ""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleExpensesEditSave}
            style={{
              color: "white",
              backgroundColor: "#007bff",
              fontSize: "12px",
              borderRadius: "20px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={revenueTypeEditModal}
        onHide={() => setRevenueTypeEditModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData
              ? filteredColumns
                .filter(col => col.field !== "index" && col.field !== "revenueEdit" && col.field !== "revenueDelete") // Exclude unwanted fields
                .map((col) =>
                  <Form.Group key={col.field} className="mb-3">
                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>

                    <Form.Control
                      type="text"
                      value={editedData[col.field]}
                      onChange={(e) => {
                        setEditedData({
                          ...editedData,
                          [col.field]: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                )
              : ""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleRevenueEditSave}
            style={{
              color: "white",
              backgroundColor: "#007bff",
              fontSize: "12px",
              borderRadius: "20px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateLaneModal}
        onHide={() => setShowCreateLaneModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData
              ? filteredColumns.map((col) =>
                col.field !== "index" && col.field !== "createLaneEdit" ? (
                  <Form.Group key={col.field} className="mb-3">
                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>
                    {col.field === "index" ? (
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        readOnly
                      />
                    ) : col.field === "uploadDocuments" ? (
                      <Form.Control
                        type="file"
                        onChange={(e) => {
                          // Handle multiple document upload logic here
                          const files = e.target.files;
                          const imageURL = URL.createObjectURL(files[0]);
                          setEditedData({ ...editedData, image: imageURL });
                        }}
                        multiple
                      />
                    ) : (
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        onChange={(e) => {
                          setEditedData({
                            ...editedData,
                            [col.field]: e.target.value,
                          });
                        }}
                      />
                    )}
                  </Form.Group>
                ) : null
              )
              : ""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={updateHandleCreateLaneSave}
            style={{
              color: "white",
              backgroundColor: "#007bff",
              fontSize: "12px",
              borderRadius: "20px",
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading ? (
            <div>Loading...</div>
          ) : Array.isArray(imageData) && imageData.length > 0 ? (
            <div className="imageset">
              {imageData.map((src, index) => (
                <img
                  key={index}
                  src={`data:${src.type}; base64, ${btoa(
                    String.fromCharCode.apply(null, new Uint8Array(src.data))
                  )
                    } `}
                  alt={src.data.data}
                  onError={(e) => {
                    e.target.src = "placeholder.jpg"; // Replace 'placeholder.jpg' with your placeholder image URL
                  }}
                />
              ))}
            </div>
          ) : (
            <Empty description="No images available" />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRajivStudentImageView(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2">
            {editedData && colFields.length > 0 ? (
              colFields.map((col) => (
                <Form.Group key={col.field} className="mb-3">
                  {col.field === "Srno" || col.field === "index" ? null : (
                    <>
                      <Form.Label>{col.header}</Form.Label>
                      {col.editor ? (
                        // Use the editor if it exists (for dropdowns)
                        col.editor({
                          value: editedData[col.field],
                          editorCallback: (newValue) =>
                            setEditedData((prevData) => ({
                              ...prevData,
                              [col.field]: newValue,
                            })),
                        })
                      ) : (
                        // Otherwise, display the normal value
                        <Form.Control
                          type="text"
                          value={editedData[col.field] || ''}
                          onChange={(e) =>
                            setEditedData((prevData) => ({
                              ...prevData,
                              [col.field]: e.target.value,
                            }))
                          }
                        />
                      )}
                    </>
                  )}
                </Form.Group>
              ))
            ) : null}

            {editedData && filteredColumns.length > 0 && colFields.length == 0 ? (filteredColumns.map((col) => (
              <Form.Group key={col.field} className="mb-3">
                {col.field === "Srno" || col.field === "index" ? null : (
                  <>
                    <Form.Label>{getCaps(col.header, col.field)}</Form.Label>

                    {/* Handle specific fields like franchiseeSchool and studentName (disabled text inputs) */}
                    {col.field === "franchiseeSchool" || col.field === "studentName" ? (
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        disabled
                      />
                    ) : col.field === "hasParentAttend" ? (
                      // Handle the dropdown for boolean fields like hasParentAttend
                      <Form.Select
                        value={editedData[col.field] ? "true" : "false"} // Set default value
                        onChange={(e) =>
                          setEditedData((prevData) => ({
                            ...prevData,
                            [col.field]: e.target.value === "true", // Convert the string to boolean
                          }))
                        }
                      >
                        <option value="">Select an option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                    ) : (
                      // Handle other fields (regular editable text inputs)
                      <Form.Control
                        type="text"
                        value={editedData[col.field]}
                        onChange={(e) =>
                          setEditedData((prevData) => ({
                            ...prevData,
                            [col.field]: e.target.value,
                          }))
                        }
                      />
                    )}
                  </>
                )}
              </Form.Group>
            ))) : null}
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditModal(false)}
            style={{
              color: "white",
              backgroundColor: "#b0aeac",
              fontSize: "12px",
              marginRight: "10px",
              borderRadius: "20px",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{
              color: "white",
              backgroundColor: "#1976D2",
              fontSize: "12px",
              borderRadius: "20px",
            }}
            onClick={handleEditSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Items</Modal.Title>
        </Modal.Header>
        <Button style={{ background: "blue", color: "white", width: "10%", marginLeft: "80%", borderRadius: "10px", marginTop: "2%" }} variant="Primary" onClick={() => handleOpenModal()}>
          Add
        </Button>
        <Modal.Body>
          {selectedClass && (
            <TableContainer className="table-container">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "white", fontWeight: "bold" }}>Program Name</TableCell>
                    <TableCell sx={{ backgroundColor: "white", fontWeight: "bold" }}>Class Name</TableCell>
                    <TableCell sx={{ backgroundColor: "white", fontWeight: "bold" }}>Sections</TableCell>
                    <TableCell sx={{ backgroundColor: "white", fontWeight: "bold" }}>Subjects</TableCell>
                    <TableCell sx={{ backgroundColor: "white", fontWeight: "bold" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {classRows && selectedClass.classes?.map((classInfo, index, classRow) => (
                    <TableRow key={classRow.id}>
                      {/* Program Dropdown */}
                      <TableCell className="tablecell">
                        {isEditing === classInfo ? (
                          <SearchableDropDown
                            value={selectedProgram?.name || ''}// Ensure correct value binding
                            onChange={(selectedOption) => handleProgramChange(classRow.id, selectedOption)}
                            placeholder="Select Program"
                            options={programsData.map((option) => ({
                              id: option.id,
                              value: option.name,
                              label: option.name,
                            }))}
                            feedbackMessage={formErrors[classRow.id - 1]?.programId && "Please select a program."}
                            validated={validated}
                          />
                        ) : (
                          classInfo.programName
                        )}
                      </TableCell>

                      {/* Class Dropdown (Dependent on Program Selection) */}
                      <TableCell className="tablecell">
                        {isEditing === classInfo ? (
                          <SelectingValuesDropDownField
                            value={classRow.className}
                            className="input-style"
                            onChange={(selectedOption) => handleClassChange(classRow.id, selectedOption)}
                            placeholder="Select Class"
                            options={classes.map((option) => ({
                              id: option.id,
                              value: option.name,
                              label: option.name,
                            }))}
                            feedbackMessage={formErrors[classRow.id - 1]?.classId && "Please select a class."}
                            validated={validated}
                          />
                        ) : (
                          classInfo.className
                        )}
                      </TableCell>

                      <TableCell className="tablecell">
                        {isEditing === classInfo ? (
                          // Dropdown for sections in edit mode
                          <SelectingValuesDropDownField
                            isMulti={true}
                            className="input-style"
                            placeholder="Select Section"
                            options={sections.map((option) => ({
                              id: option.id,
                              value: option.name,
                              label: option.name,
                            }))}
                            onChange={(selectedOption) => handleSectionChange(classRow.id, selectedOption)}
                            validated={validated}
                          />
                        ) : (
                          // Display sections with delete buttons in non-edit mode
                          showSectionDelete === classInfo.classId && classInfo.sections && classInfo.sections.length > 0 ? (
                            classInfo.sections.map((section) => (
                              <div key={section.id} style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}>{section.name}</span>
                                <button
                                  style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#ff4d4f',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleSectionDelete(classInfo.classId, section.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            ))
                          ) : (
                            <Tooltip title={classInfo.sections.map(section => section.name).join(', ')}>
                              <span>{`${classInfo.sections.slice(0, 1).map(section => section.name)},...`}</span>
                            </Tooltip>
                          )
                        )}
                      </TableCell>


                      <TableCell className="tablecell">
                        {classInfo.subjects && classInfo.subjects.length > 0 ? (
                          <Tooltip title={classInfo.subjects.join(', ')}>
                            <span>{`${classInfo.subjects.join(', ').substring(0, 14)}...`}</span>
                          </Tooltip>
                        ) : (
                          <span>N/A</span>
                        )}
                      </TableCell>

                      <TableCell className="tablecell">
                        {isEditing === classInfo ? (
                          <>
                            <button style={{ marginRight: '5px', fontSize: "12px", fontWeight: "bold" }}
                              onClick={() => handleSave(classInfo)}>Save</button>
                            <button style={{ fontSize: "12px", fontWeight: "bold" }} onClick={handleClassCancel}>Cancel</button>
                          </>
                        ) : (
                          <>
                            {/* Add 3 dots icon */}
                            <button onClick={(e) => handleShowOptions(e, classInfo)}>
                              <span className="dots">⋮</span>
                            </button>
                            {showOptions === classInfo && (
                              <div className="options-container">
                                <button onClick={() => handleClassEdit(classInfo)}>Edit</button>
                                <button onClick={() => handleClassDelete(classInfo.classId)}>Delete</button>
                              </div>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>{popupType === 'success' ? 'Success' : 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{popupMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant={popupType === 'success' ? 'success' : 'danger'} onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Class" : "Add Class"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={isEditing ? handleEditSubmit : handleAddSubmit}>
            {classRows.map((classRow) => (
              <Row key={classRow.id}>
                <Col sm={6}>
                  <SearchableDropDown
                    value={programsData.find((data) => data.id === classRow.programId)?.name || ""}// Ensure correct value binding
                    onChange={(selectedOption) => handleProgramChange(classRow.id, selectedOption)}
                    placeholder="Select Program"
                    options={programsData.map((option) => ({
                      id: option.id,
                      value: option.name,
                      label: option.name,
                    }))}
                    feedbackMessage={formErrors[classRow.id - 1]?.programId && "Please select a program."}
                    validated={validated}
                  />
                </Col>
                <Col sm={6}>
                  <SelectingValuesDropDownField
                    value={classRow.className}
                    className="input-style"
                    onChange={(selectedOption) => handleClassChange(classRow.id, selectedOption)}
                    placeholder="Select Class"
                    options={classes.map((option) => ({
                      id: option.id,
                      value: option.name,
                      label: option.name,
                    }))}
                    feedbackMessage={formErrors[classRow.id - 1]?.classId && "Please select a class."}
                    validated={validated}
                  />
                  {formErrors[classRow.id - 1]?.classId && (
                    <div className="error-message">
                      Please select a class
                    </div>
                  )}
                </Col>
                <Col sm={6}>
                  <SelectingValuesDropDownField
                    isMulti={true}
                    value={classRow.selectedSection}
                    className="input-style"
                    onChange={(selectedOption) => handleSectionChange(classRow.id, selectedOption)}
                    placeholder="Select Section"
                    options={sections.map((option) => ({
                      id: option.id,
                      value: option.name,
                      label: option.name,
                    }))}
                    feedbackMessage={formErrors[classRow.id - 1]?.selectedSection && "Please select at least one section."}
                    validated={validated}
                  />
                </Col>
              </Row>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={isEditing ? handleEditSubmit : handleAddSubmit}>
            {isEditing ? "Save Changes" : "Add Class"}
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={showDocumentPopup1} onHide={handleCloseDocumentPopup1} className="modal">
        <Modal.Header closeButton>
          <Modal.Title>View Bonafide doc</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDocument && (
            <div>
              <Image
                src={selectedDocument}
                alt="Document"
                style={{ width: '10rem', height: '12rem' }}
              />
              <Button
                style={{ marginLeft: '2rem', color: 'blue' }}
                onClick={() => handleDownload1(selectedDocument)}
              >
                <DownloadOutlined style={{ fontSize: '24px', color: 'blue' }} />
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseDocumentPopup1}
            style={{
              color: 'white',
              backgroundColor: '#b0aeac',
              fontSize: '12px',
              marginRight: '10px',
              borderRadius: '20px',
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <DueDatesModal
        show={showDueDates}
        handleClose={handleDueDatesClose}
        dueDates={dueDates}
      />
      <DueDatesModalWithAmount
        show={showDueDatesWithAmount}
        handleClose={handleDueDatesCloseWithAmount}
        dueDates={dueDatesWithAmount}
      />
      <FeeDueModel
        show={showDueFee}
        handleClose={handleCloseDueFee}
        dueDates={dueFee}
      />
      <PaymentTransaction
        show={showPaymentTransaction}
        handleClose={handleClosePaymentTransaction}
        paymentTransactions={paymentTransaction}
      />
    </div>
  );
};

export default TableView;