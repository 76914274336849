import React, { useState, useEffect } from "react";
import Table from "../reUsableForm/table/table";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubjectDataOnce } from "./slice/viewSubjectSlice";
import { editSubjectSlice } from "./slice/subjectEditSlice";
import { setShowErrorMessage, setShowSuccessMessage } from './slice/subjectEditSlice'
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";

function ViewSubject() {

  const [showEditModal, setShowEditModal] = useState(false);
  const [editedData, setEditedData] = useState({});

  const columns = [
    { field: "index", header: "Sr.no" },
    { field: "name", header: "Subject" },
    { field: "isActivity", header: "Is Activity" },
    { field: "className", header: "Class Name" },
    { field: 'edit', header: 'Edit' },

  ];

  const sendColumns = [
    {
      field: "name",
      header: "Name",
      editor: (options) => (
        <div>
          <input
            type="text"
            value={options.value || ""}
            onChange={(e) => options.editorCallback(e.target.value)}
          />
        </div>
      )
    },
    {
      field: "isActivity",
      header: "Is Activity",
      editor: (options) => (
        <div>
          <input
            type="checkbox"
            checked={options.value === "Yes"} // Convert the string to a boolean
            onChange={(e) =>
              options.editorCallback(e.target.checked ? "Yes" : "No") // Convert back to string
            }
          />
        </div>
      )
    }
  ]

  const apiData = useSelector((state) => state.viewSubject.data) || [];
  const postData = useSelector((state) => state.addSubject.data) || [];
  const editData = useSelector((state) => state.editSubject.data) || [];
  const classData1 = useSelector((state) => state.viewClass.data) || [];




  const showSuccessMsg = useSelector((state) => state.editSubject.showSuccessMessage);
  const successMsg = useSelector((state) => state.editSubject.successMessage);

  const showErrorMsg = useSelector((state) => state.editSubject.showErrorMessage);
  const errorMsg = useSelector((state) => state.editSubject.errorMessage);

  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubjectDataOnce());
  }, [dispatch]);

  const handleUpdate = (id, data) => {
    const updatedData = {
      name: data.name,
      isActivity: data.isActivity === "Yes"
    }
    dispatch(editSubjectSlice(id, updatedData))
    setShowEditModal(true);

  }

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };
  const combinedData = [...apiData, ...postData].map(row => {
    const classNameObj = classData1.find(cls => cls.id === row.classId);
    const className = classNameObj ? classNameObj.name : ""; // Get className if found, otherwise set to empty string
    return {
      ...row,
      className: className
    };
  });

  // Step 1: Remove duplicates in combinedViewData by id
  const uniqueCombinedData = Array.from(
    new Map(combinedData.map(item => [item.id, item])).values()
  );

  // Step 2: If putViewData is not empty, create a map to update items
  const putDataMap = new Map(editData.map(item => [item.id, item]));

  // Step 3: Merge uniqueCombinedData with putDataMap if there are updates
  const mergedData = uniqueCombinedData.map(item => {
    return putDataMap.has(item.id) ? { ...item, ...putDataMap.get(item.id) } : item;
  });

  // If putViewData has items not in uniqueCombinedData, add them
  const additionalItems = editData.filter(item =>
    !uniqueCombinedData.some(existingItem => existingItem.id === item.id)
  );

  const finalData = [...mergedData, ...additionalItems];

  const processedData = finalData.map((row, index) => ({
    ...row,
    isActivity: row.isActivity ? "Yes" : "No",
    index: index + 1,
  }));


  return (

    <>

      {processedData === null ? (
        <center><p>Loading...</p></center>
      ) : processedData.length === 0 ? (
        <center style={{ color: 'blue', margin: '1rem' }}><p>No records available</p></center>
      ) :
        (
          <Table data={processedData} columns={columns} hover={true} striped={true}
            colFields={sendColumns}
            showEditModal={showEditModal}
            setShowEditModal={setShowEditModal}
            editedData={editedData}
            setEditedData={setEditedData}
            handleUpdate={handleUpdate}
          />
        )
      }
      <SuccessMessagePopup show={showSuccessMsg} onClose={handleCloseSuccessPopup} successMessage={successMsg} />
      <ErrorMessagePopup show={showErrorMsg} onClose={handleCloseErrorPopup} errorMessage={errorMsg} />
    </>
  );
}
export default ViewSubject;