import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import TextInputField from "../reUsableForm/textInputField";
import { validationRegexPinNo, validationRegexOnlyAlpha1, validationRegexAddress } from "../reUsableForm/regex";
import SuccessMessagePopup from "../reUsableForm/pop-card/successfulMessage";
import { ErrorMessagePopup } from "../reUsableForm/pop-card/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import Table from "../reUsableForm/table/table3";
import "../../components/reUsableForm/table/table.css";
import { API_PATH } from "../../Api/api";
import { franchiseeSchoolId, token } from "../../Api/token";
import { fetchStudentDataOnce } from "../StudentDetails/viewStudentSlicer";
import { fetchViewEventStatusDataOnce } from "../event_Holidays/slice/viewEventStatusSlice";
import { PostTermAssessment, setShowSuccessMessage, setShowErrorMessage } from "./slicer/addTermAssessmentSlice";

const TermAssessment = () => {

  const [validated, setValidated] = useState(false);
  const [dropDowns, setDropDowns] = useState({});
  const [subjects, setSubjects] = useState([]);

  const [formData, setFormData] = useState({
    studentNameId: null,
    assessmentId: null,
    class: "",
    marks: "",
    classId: "",
  });


  const FranchiseeSchoolId = Number(franchiseeSchoolId());


  const [showTable, setShowTable] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const showSuccessMsg = useSelector(
    (state) => state.addTermAssessment.showSuccessMessage
  );
  const successMsg = useSelector(
    (state) => state.addTermAssessment.successMessage
  );

  const showErrorMsg = useSelector(
    (state) => state.addTermAssessment.showErrorMessage
  );

  const errorMsg = useSelector(
    (state) => state.addTermAssessment.errorMessage
  );

  const eventStatusData = useSelector((state) => state.viewEventStatus?.data) || [];



  useEffect(() => {
    if (eventStatusData.length == 0) {
      dispatch(fetchViewEventStatusDataOnce());
    }
  }, [dispatch, eventStatusData]);


  const eventStatusGetData = eventStatusData.map((row, index) => ({
    ...row,
    index: index + 1,
    isConductedValue: row.isConducted === true ? 'Yes' : 'No',
    eventHolidayName: row.eventHoliday?.name || ''
  }));



  const [formErrors, setFormErrors] = useState({
    studentName: false,
    assessment: false,
    class: false,
  });

  const assessmentOptions = [
    { id: 1, assessment: "Term 1" },
    { id: 2, assessment: "Term 2" },
  ];


  const columns = [
    { field: "subject", header: "Subject" },
    {
      field: "writtenMaxMarks",
      header: (
        <span>
          Written<br />
          Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
        </span>
      ),
      inputType: "number",
      handleMarksChange: (e, row) => handleMarksChange(e, row, "writtenMaxMarks")
    },
    {
      field: "oralMaxMarks",
      header: (
        <span>
          Orals<br />
          Max Marks<span style={{ paddingLeft: '50px' }}></span>Scored
        </span>
      ),
      inputType: "number",
      handleMarksChange: (e, row) => handleMarksChange(e, row, "oralMaxMarks")
    },
    {
      field: "totalMaxMarks",
      header: (
        <span>
          Total <span style={{ paddingRight: '10px' }}></span><br />
          Max Marks
        </span>
      ),
      inputType: "number"
    },
    {
      field: "totalScoreMarks",
      header: (
        <span>
          Total<br />
          Scored
        </span>
      ),
      inputType: "number"
    },
    { field: "grade", header: "Grade" },
    { field: "remarks", header: "Remarks" },
  ];

  const eventStatusColumns = [
    { field: "index", header: "Sr.No" },
    { field: "eventHolidayName", header: "Event Name" },
    { field: "isConductedValue", header: "Has Conducted" },
    { field: "conductedFromDate", header: "Conducted From Date" },
    { field: "conductedToDate", header: "Conducted To Date" },
  ];

  const viewStudentData = useSelector((state) => state.viewStudent.data) || [];

  useEffect(() => {
    if (viewStudentData.length === 0) {
      dispatch(fetchStudentDataOnce(FranchiseeSchoolId));
    }
  }, [viewStudentData, dispatch]);

  const assessmentSkillData =
    useSelector((state) => state.viewAssessment.data) || [];


  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          classId: formData.classId,
          franchiseeSchoolId: FranchiseeSchoolId,
        });
        if (formData.classId) {

          const url = `${API_PATH}/class/${formData.classId}/subjects?isActivity=false`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token()}`,
            },
          });

          if (response.ok) {
            const responseData = await response.json();
            setSubjects(responseData);
          }
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [formData.classId, token]);

  const handleSearchClick = (e) => {
    e.preventDefault();


    const newFormErrors = {
      studentName: !dropDowns.studentName,
      assessment: !dropDowns.assessment,
    };


    setFormErrors(newFormErrors);

    const hasErrors = Object.values(newFormErrors).some((error) => error);
    setFormErrors(newFormErrors);


    if (hasErrors) {
      setValidated(true);
      return;
    }

    // const filteredSkills = assessmentSkillData.filter(
    //   (skill) => skill.classId === formData.classId
    // );

    // const dynamicColumns1 = filteredSkills.map((skill) => ({
    //   skillId: skill.id,
    //   label: skill.name,
    //   value: skill.name,
    // }));
    // setSkillData(dynamicColumns1);
    const updatedData = subjects.map((row) => ({
      ...row,
      subject: row?.name || 0,
      oralScoreMarks: row?.oralScoreMarks || 0,
      totalMaxMarks: calculateTotalMaxMarks(row),
      totalScored: calculateTotalScoredMarks(row),
      grade: calculateGrade(formData?.marks || 0),
    }));
    setData(updatedData);
    setShowTable(true);
  };

  const calculateGrade = (totalScoreMarks, totalMaxMarks) => {
    const percentage = Math.ceil((totalScoreMarks / totalMaxMarks) * 100);
    if (percentage >= 91 && percentage <= 100) {
      return "A1";
    } else if (percentage >= 81 && percentage <= 90) {
      return "A2";
    } else if (percentage >= 71 && percentage <= 80) {
      return "B1";
    } else if (percentage >= 61 && percentage <= 70) {
      return "B2";
    } else if (percentage >= 51 && percentage < 60) {
      return "C1";
    } else if (percentage >= 41 && percentage < 50) {
      return "C2";
    } else {
      return "D";
    }
  };

  const handleMarksChange = (e, row, field) => {
    let inputMarks;
    let updatedRow;
    const values = e.target.value
    if (field === "remarks") {
      updatedRow = { ...row, "remarks": e.target.value };
    } else if (values !== '') {
      inputMarks = Number(e.target.value);
      updatedRow = { ...row, [field]: inputMarks };
    } else {
      updatedRow = { ...row, [field]: '' };
    }

    const writtenMaxMarks = parseFloat(updatedRow.writtenMaxMarks) || 0;
    const writtenScoreMarks = parseFloat(updatedRow.writtenScoreMarks) || 0;
    const oralMaxMarks = parseFloat(updatedRow.oralMaxMarks) || 0;
    const oralScoreMarks = parseFloat(updatedRow.oralScoreMarks) || 0;

    if (writtenScoreMarks > writtenMaxMarks) {
      return;
    }

    if (oralScoreMarks > oralMaxMarks) {
      return;
    }

    const totalMaxMarks = writtenMaxMarks + oralMaxMarks;
    const totalScoreMarks = writtenScoreMarks + oralScoreMarks;
    const updatedRowWithCalculations = {
      ...updatedRow,
      totalMaxMarks,
      totalScoreMarks,
      grade: calculateGrade(totalScoreMarks, totalMaxMarks)
    };

    // Update the state with the updated row
    setData((prevData) => {
      // Update the specific row in the data
      const updatedData = prevData.map((rowData) =>
        rowData === row ? updatedRowWithCalculations : rowData
      );

      // Remove the "Total" row and recalculate totals
      const filteredData = updatedData.filter((row) => row.subject !== "Total");
      const updatedTotals = calculateTotals(filteredData);

      // Add the recalculated "Total" row back to the data
      return [...filteredData, updatedTotals];
    });
  };

  const calculateTotalScoredMarks = (row) => {
    // Assuming written and oral scored marks are fields in your row data
    const writtenScored = parseFloat(row?.writtenMaxMarks) || 0;
    const oralScored = parseFloat(row?.oralMaxMarks) || 0;
    return writtenScored + oralScored;
  };
  const calculateTotalMaxMarks = (row) => {
    // Assuming written and oral max marks are fields in your row data
    const writtenMax = parseFloat(row?.writtenMaxMarks) || 0;
    const oralMax = parseFloat(row?.oralMaxMarks) || 0;
    return writtenMax + oralMax;
  };


  const calculateTotals = (data) => {
    const initialTotals = {
      writtenMaxMarks: 0,
      writtenMaxMarks: 0,
      writtenScoreMarks: 0,
      oralMaxMarks: 0,
      oralScoreMarks: 0,
      totalMaxMarks: 0,
      totalScoreMarks: 0,
    };

    const totals = data.reduce((totals, row) => {
      totals.writtenMaxMarks += parseFloat(row.writtenMaxMarks) || 0;
      totals.writtenScoreMarks += parseFloat(row.writtenScoreMarks) || 0;
      totals.oralMaxMarks += parseFloat(row.oralMaxMarks) || 0;
      totals.oralScoreMarks += parseFloat(row.oralScoreMarks) || 0;
      totals.totalMaxMarks += parseFloat(row.totalMaxMarks) || 0;
      totals.totalScoreMarks += parseFloat(row.totalScoreMarks) || 0;
      return totals;
    }, initialTotals);
    totals.subject = "Total";
    totals.grade = calculateGrade(totals.totalScoreMarks, totals.totalMaxMarks);
    return totals;

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const sendData = {
      studentId: formData.studentNameId,
      assessment: formData.assessment,
      subjectsAssessments: data.map((obj) => ({
        writtenMaxMarks: obj?.writtenMaxMarks || 0,
        writtenScoreMarks: obj?.writtenScoreMarks || 0,
        oralMaxMarks: obj?.oralMaxMarks || 0,
        oralScoreMarks: obj?.oralScoreMarks || 0,
        totalMaxMarks: obj?.totalMaxMarks || 0,
        totalScoreMarks: obj?.totalScoreMarks || 0,
        grade: obj?.grade || "",
        subjectId: obj?.id || "",
        remarks: obj?.remarks || "",
      })),

    };
    dispatch(PostTermAssessment(sendData));

  };

  const handleClear = () => {
    setData([]);
    setFormData({
      studentNameId: null,
      assessmentId: null,
      class: "",
    });

    setDropDowns({});
    setShowTable(false)
    setValidated(false);
  };


  const handleCloseSuccessPopup = () => {
    dispatch(setShowSuccessMessage(false));
    handleClear();
  };

  const handleCloseErrorPopup = () => {
    dispatch(setShowErrorMessage(false));
  };

  return (
    <>
      <div className="content-header">Term Assessment</div>
      <Row>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SelectDropdown
            value={
              dropDowns.assessment
                ? { value: dropDowns.assessment, label: dropDowns.assessment }
                : null
            }
            className="input-style"
            options={assessmentOptions.map((option) => ({
              id: option.id,
              value: option.assessment,
              label: option.assessment,
            }))}
            onChange={(selectedOption) => {
              setFormData({
                ...formData,
                assessment: selectedOption.value,
              });
              setDropDowns({
                ...dropDowns,
                assessment: selectedOption.value,
              });
              setFormErrors({
                ...formErrors,
                assessment: false,
              });
            }}
            placeholder="Select a term*"
          />
          {formErrors.assessment && (
            <div className="error-message">Please select a term.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <SelectDropdown
            value={
              dropDowns.studentName
                ? {
                  value: dropDowns.studentName,
                  label: dropDowns.studentName,
                }
                : null
            }
            className="input-style"
            options={viewStudentData.map((option) => ({
              id: option.id,
              value: option.name,
              label: option.name,
              class:
                option.studentEnrollments[0]?.classesClassSection?.class
                  ?.name || "", // Access class information
              classId:
                option.studentEnrollments[0]?.classesClassSection?.class?.id ||
                "",
            }))}
            onChange={(selectedOption) => {
              setFormData({
                ...formData,
                studentNameId: selectedOption.id,
                class: selectedOption.class,
                classId: selectedOption.classId, // Update the class in the form data
              });
              setDropDowns({
                ...dropDowns,
                studentName: selectedOption.value,
              });
              setFormErrors({
                ...formErrors,
                studentName: false,
                class: false, // Update the class in the form errors
              });
            }}
            placeholder="Select a student name*"
          />
          {formErrors.studentName && (
            <div className="error-message">Please select a studentName.</div>
          )}
        </Col>

        <Col sm={6} xs={12} md={6} lg={3} className="rowCss">
          <TextInputField
            name="class"
            placeholder="Class*"
            textType="Alphabet"
            required
            value={formData.class}
            feedbackMessage="Please enter a class."
            validated={validated}
            validationRegex={validationRegexOnlyAlpha1}
            validationErrorMessage="Please enter a valid class."
          />
        </Col>

        <Col sm={12} xs={12} md={6} lg={3} className="rowCss">
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
          }}>

            <button
              className="save-button-figma-div"
              onClick={handleSearchClick}
            >
              Search
            </button>
          </div>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {showTable && (
          <Row>
            <Col sm={12} xs={12} md={6} lg={12} className="rowCss">
              {data === null ? (
                <center>
                  <p>Loading...</p>
                </center>
              ) : data.length === 0 ? (
                <center style={{ color: "blue", margin: "1rem" }}>
                  <p>No records available</p>
                </center>
              ) : (
                <>
                  <Table
                    data={data}
                    columns={columns}
                    handleMarksChange={handleMarksChange}
                  />
                </>
              )}
            </Col>
            <Col className="rowCss">
              <div className="content-header ">Event Status</div>
              <Table
                data={eventStatusGetData}
                columns={eventStatusColumns}
              // hover={true}
              // striped={true}
              />
            </Col>
          </Row>
        )}
        <SuccessMessagePopup
          show={showSuccessMsg}
          onClose={handleCloseSuccessPopup}
          successMessage={successMsg}
        />
        <ErrorMessagePopup
          show={showErrorMsg}
          onClose={handleCloseErrorPopup}
          errorMessage={errorMsg}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1%",
            marginTop: "5%",
          }}
        >
          <button
            className="clear-button-figma-div"
            type="button"
            onClick={handleClear}
          >
            Clear
          </button>
          <button className="save-button-figma-div" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </>
  )
};

export default TermAssessment;
